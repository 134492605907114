

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { Azienda } from "src/app/models/fragile/azienda.model";
import { Progetto } from "src/app/models/fragile/progetto.model";
import { AziendeService } from "src/app/services/aziende.service";
import { ProgettiService } from "src/app/services/progetti.service";




@Component({
  templateUrl: "./dialog-modifica-progetto.component.html",
  styleUrls: ["../home.component.css"],
})
export class ModificaProgettoComponent implements OnInit {
  protected _onDestroy = new Subject<void>();
  public modificaProgettoForm: FormGroup;
  public progettoSelezionato: Progetto;
  protected aziende: Azienda[]  = [];

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private progettiService: ProgettiService,
    private aziendeService: AziendeService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder
  ) {
    this.loadAziende();
  }

  ngOnInit(): void {
    this.progettoSelezionato = this.config.data.progetto;
    this.modificaProgettoForm = this._formBuilder.group({
      //identificativo:   [ this.progettoSelezionato.identificativo, [Validators.required]],
      descrizione:   [ this.progettoSelezionato.descrizione],
      azienda:       [null, [Validators.required]],
    });
    


  }

  loadAziende(){
    this.aziendeService.get().subscribe(aziendeFromDB=>{
      this.aziende = aziendeFromDB;
      
      let aziendaRes = this.aziende.filter((azienda)=>{return azienda.id == Number(this.progettoSelezionato.idAzienda)})[0] as Azienda;
      this.logger.info(aziendaRes);
       this.modificaProgettoForm.setValue({ 
        azienda: aziendaRes,
        descrizione:   [ this.progettoSelezionato.descrizione],
      });
    });
  }



  getAziendaClass(azienda: Azienda): string {
    if (azienda.ragioneSociale.toUpperCase() === "VERTICAL SRL") {
      return "azienda-vertical";
    }else{
      return "azienda-altre";
    }
  }

  

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let progettoDaModificare: Progetto = new Progetto();
      progettoDaModificare.id = this.progettoSelezionato.id;
      //progettoDaModificare.identificativo = this.modificaProgettoForm.value.identificativo;
      progettoDaModificare.descrizione  = this.modificaProgettoForm.value.descrizione;
      progettoDaModificare.idAzienda    = this.modificaProgettoForm.value.azienda.id;
      this.logger.info(progettoDaModificare);
      this.progettiService.modifica(progettoDaModificare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          console.error("ERRORE MODIFICA PROGETTO" + error);
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di modifica progetto!", life:10000
          });
        }
      );
    }
  }
}
