import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { MisurazioniComponent } from './pages/misurazioni/misurazioni.component';
import { UtentiComponent } from './pages/utenti/utenti.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { RoleGuard } from './auth/role.guard';
import { AziendeComponent } from './pages/aziende/aziende.component';



const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: 'misurazione/:idProgetto/:idPunto/:layer', component: MisurazioniComponent, pathMatch: 'full'},
  {path: 'utenti',  component: UtentiComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['ADMIN', 'SUPERADMIN']},},
  {path: 'utenti/azienda/:idAzienda', component: UtentiComponent, canActivate: [AuthGuard, RoleGuard],data: {allowedRoles: ['ADMIN', 'SUPERADMIN']}},
  {path: 'progetti', component: HomeComponent, canActivate: [AuthGuard, RoleGuard],data: {allowedRoles: ['ADMIN', 'SUPERADMIN']}},
  {path: 'aziende',  component: AziendeComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['ADMIN', 'SUPERADMIN']},},
  //{path: 'progetti/azienda/:idAzienda',  component: AziendeComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['ADMIN', 'SUPERADMIN']},},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
