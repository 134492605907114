
<ng-template [ngIf]="isCreatingProject">
      <app-crea-progetto-loading></app-crea-progetto-loading>
</ng-template>
    
<p-steps [model]="pagineCreaProgetto" [readonly]="true" [activeIndex]="activeIndex" ></p-steps>
    <div class="d-flex mt-2">
        <ng-container [ngSwitch]="activeIndex">
          <ng-container *ngSwitchCase="0">
            <form [formGroup]="creaProgettoForm">
                 <div class="grid formgrid p-fluid">
                   
                    <div class="field mt-2 mb-2 col-8">
                        <label for="identificativo" class="font-medium text-900 text-green-600">IDENTIFICATIVO</label>
                        <span class="p-input-icon-left">
                            <i class="pi pi-bookmark"></i>
                            <input id="identificativo" type="text"  formControlName="identificativo" pInputText placeholder="Identificativo SENZA spazi">
                            
                        </span>
                        <small class="mt-3" *ngIf="creaProgettoForm.get('identificativo').hasError('noSpaces') && creaProgettoForm.get('identificativo').touched" >
                            L'identificativo NON deve contenere SPAZI.
                        </small>
                    </div>
                    <div class="field mt-2 mb-2 col-4">
                        <label for="azienda" class="font-medium text-900 text-blue-600">AZIENDA</label>
                        <span class="p-input-icon-left">
                            <i class="pi pi-bookmark"></i>
                            <p-dropdown inputId="azienda" [options]="aziende" optionLabel="ragioneSociale" [showClear]="true"
                                placeholder="Scegli l'azienda" formControlName="azienda">
                                <ng-template let-azienda pTemplate="item">
                                    <div class="flex align-items-center">
                                        <div [style]="{
                                            'background-color': azienda.colore ,
                                            'color': '#ffffff' ,
                                            'border-radius': '6px',
                                            'padding': '.25em .5rem',
                                            'font-weight': '700',
                                            'font-size': '13px',
                                            'letter-spacing': '.3px;',
                                            'cursor': 'pointer',
                                          }">{{azienda.ragioneSociale | uppercase}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </span>
                    </div>
                    <div class="field mt-2 mb-2 col-12">
                        <label for="descrizione" class="font-medium text-900 text-orange-400">DESCRIZIONE</label>
                        <span class="p-input-icon-left">
                            <i class="pi pi-info-circle"></i>
                            <textarea id="descrizione" rows="3" [autoResize]="true" formControlName="descrizione" pInputTextarea></textarea>
                        </span>
                    </div>
                    <div class="field mt-2 mb-2 col-12">
                        <label for="descrizione" class="font-medium text-900" style="color: #64A6BD !important">LAYER CON MISURAZIONI</label>
                        <div class="flex flex-wrap gap-3 mt-1 mb-2">
                            <div class="flex align-items-center">
                                <p-radioButton 
                                    name="ascSelected" 
                                    value="true" 
                                    inputId="MT_SBA_ASC" 
                                    variant="filled"
                                    formControlName="ascSelected"
                                    />
                                <label for="MT_SBA_ASC" class="ml-2" style="font-weight: bold;">
                                    MT_SBA_ASC
                                </label>
                            </div>
                            <div class="flex align-items-center">
                                <p-radioButton 
                                    name="descSelected" 
                                    value="true" 
                                    inputId="MT_SBA_DESC" 
                                    variant="filled"
                                    formControlName="descSelected"
                                    />
                                <label for="MT_SBA_DESC" class="ml-2" style="font-weight: bold;">
                                   MT_SBA_DESC
                                </label>
                            </div>
                            <div class="flex align-items-center">
                                <p-radioButton 
                                    name="verticaleSelected" 
                                    value="true" 
                                    inputId="MT_SBA_VERTICALE" 
                                    variant="filled"
                                    formControlName="verticaleSelected"
                                    />
                                <label for="MT_SBA_VERTICALE" class="ml-2" style="font-weight: bold;">
                                    MT_SBA_VERTICALE
                                </label>
                            </div>
                        </div>
                    </div>
                </div> 
            </form>
          </ng-container>
          <ng-container *ngSwitchCase="1">
            <div class="grid mt-2 formgrid p-fluid">
                <div class="field mt-2 mb-2 col-6">
                  <p class="line-height-3 p-0 m-0 mb-1 mt-1">
                    Seleziona file <strong>QGIS</strong> o trascinalo nell'area sottostante
                   </p>
                    <p-fileUpload
                        multiple="false"
                        maxFileSize="5000000"
                        accept=".qgs"
                        chooseLabel="Cerca file QGIS" 
                        uploadLabel="Carica file QGIS" 
                        cancelLabel="Cancella"
                        chooseIcon="pi pi-map-marker"
                        chooseStyleClass="btn-choose-qgisFile"
                        removeStyleClass="btn-remove-qgisFile"
                        uploadStyleClass="btn-choose-qgisFile"
                        [auto]="true"
                        [customUpload]="true"
                        (uploadHandler)="onQGISFileUpload($event)"
                        [showUploadButton]="false"
                        [showCancelButton]="false"
                        (onRemove)="onQGISFileRemove($event)"
                        >
                       
                    </p-fileUpload>
                </div>
                <div class="field mt-2 mb-2 col-6">
                    <p class="line-height-3 p-0 m-0 mb-1 mt-1">
                      Seleziona file <strong>GEOPACKAGE</strong> o trascinalo nell'area sottostante
                     </p>
                     <p-fileUpload
                     multiple="false"
                     maxFileSize="100000000"
                     accept=".gpkg"
                     chooseLabel="Cerca file GEOPACKAGE" 
                     uploadLabel="Carica file GEOPACKAGE" 
                     cancelLabel="Cancella"
                     chooseStyleClass="btn-choose-geoPackageFile"
                     removeStyleClass="btn-remove-geoPackageFile"
                     uploadStyleClass="btn-choose-geoPackageFile"
                     chooseIcon="pi pi-map"
                     [auto]="true"
                     [customUpload]="true"
                     (uploadHandler)="onGeoPackageFileUpload($event)"
                     [showUploadButton]="false"
                     [showCancelButton]="false"
                     (onRemove)="onGeoPackageFileRemove($event)"

                     >
                   </p-fileUpload>
                  </div>
              </div>
          </ng-container>
          <ng-container *ngSwitchCase="2">
            <div class="grid mt-2 formgrid p-fluid">
               
                <div *ngIf="creaProgettoForm.value.ascSelected" class="field mt-2 mb-2 col-4">
                        <!-- <p  class="line-height-3 p-0 m-0 mb-1 mt-1">
                            Seleziona file <strong>CSV</strong> per il layer <strong>ASC</strong>
                        </p> -->
                        <p-fileUpload 
                            mode="basic" 
                            name="caricaLayerASC"
                            multiple="false"
                            maxFileSize="10000000000"
                            accept=".csv"
                            [auto]="false"
                            uploadLabel="Carica File CSV per il layer ASC" 
                            chooseLabel="{{labelASC}}"
                            chooseIcon="{{iconaASC}}"
                            uploadIcon="{{iconaASC}}"
                            uploadLabel="{{labelASC}}"
                            [styleClass]="classASC"
                            [showUploadButton]="false"
                            [showCancelButton]="false"
                            (onUpload)="onFileUpload($event, 'ASC')" 
                            (onSelect)="onFileSelect($event, 'ASC')" 
                            url="https://www.primefaces.org/cdn/api/upload.php"
                        >
                        </p-fileUpload>
                </div>             
                <div *ngIf="creaProgettoForm.value.descSelected" class="field mt-2 mb-2 col-4">
                    
                    <p-fileUpload 
                            mode="basic" 
                            name="caricaLayerDESC"
                            multiple="false"
                            maxFileSize="10000000000"
                            accept=".csv"
                            [auto]="false"
                            uploadLabel="Carica File CSV per il layer DESC" 
                            chooseLabel="{{labelDESC}}"
                            chooseIcon="{{iconaDESC}}"
                            uploadIcon="{{iconaDESC}}"
                            uploadLabel="{{labelDESC}}"
                            [styleClass]="classDESC"
                            [showUploadButton]="false"
                            [showCancelButton]="false"
                            (onUpload)="onFileUpload($event, 'DESC')" 
                            (onSelect)="onFileSelect($event, 'DESC')" 
                            
                            url="https://www.primefaces.org/cdn/api/upload.php"
                        >
                        </p-fileUpload>
                        
                </div>      
                <div *ngIf="creaProgettoForm.value.verticaleSelected" class="field mt-2 mb-2 col-4">
                    <p-fileUpload 
                            mode="basic" 
                            name="caricaLayerVerticale"
                            multiple="false"
                            maxFileSize="10000000000"
                            accept=".csv"
                            [auto]="false"
                            uploadLabel="Carica File CSV per il layer VERTICALE" 
                            chooseLabel="{{labelVERTICALE}}"
                            chooseIcon="{{iconaVERTICALE}}"
                            uploadIcon="{{iconaVERTICALE}}"
                            uploadLabel="{{labelVERTICALE}}"
                            [styleClass]="classVERTICALE"
                            [showUploadButton]="false"
                            [showCancelButton]="false"
                            (onUpload)="onFileUpload($event, 'VERTICALE')" 
                            (onSelect)="onFileSelect($event, 'VERTICALE')" 
                            url="https://www.primefaces.org/cdn/api/upload.php"
                        >
                        </p-fileUpload>
                        
                </div>  
                <div class="surface-border border-top-1 opacity-10 mb-3 col-12"></div>

                <!-- <div class="field mt-1 mb-2 col-4">
                    <label for="descrizione" class="font-medium text-900" style="color: #64A6BD !important">SEPARATORE USATO NEI CSV</label>
                    <p-selectButton 
                        [options]="separatoriOptions" 
                        [(ngModel)]="separatore" 
                        optionLabel="label" 
                        optionValue="value" 
                        styleClass="separatoreClass"
                       />
                </div> -->
                 <!--    <p *ngIf="creaProgettoForm.value.descSelected" class="line-height-3 p-0 m-0 mb-1 mt-1">
                        Seleziona file <strong>CSV</strong> per il layer <strong>DESC</strong>
                    </p>
                    <p *ngIf="creaProgettoForm.value.verticaleSelected" class="line-height-3 p-0 m-0 mb-1 mt-1">
                        Seleziona file <strong>CSV</strong> per il layer <strong>VERTICALE</strong>
                    </p> -->
                  <!-- <p class="line-height-3 p-0 m-0 mb-1 mt-1">
                    Seleziona file <strong>CSV</strong> da caricare o trascinalo nell'area sottostante
                   </p>
                   <p-fileUpload
                   multiple="true"
                   maxFileSize="5000000"
                   accept=".csv"
                   chooseLabel="Cerca File CSV" uploadLabel="Carica File CSV" cancelLabel="Cancella"
                   [auto]="true"
                   [customUpload]="true"
                   (uploadHandler)="onMisurazioniFileUpload($event)"
                   [showUploadButton]="false"
                   [showCancelButton]="false"
                   chooseIcon="pi pi-database"
                    chooseStyleClass="btn-choose-misurazioniFile"
                    removeStyleClass="btn-remove-misurazioniFile"
                    uploadStyleClass="btn-choose-misurazioniFile"
                   (onRemove)="onFileMisurazioniRemove()"
                   >
                 </p-fileUpload> 
                </div>-->
              </div>
          </ng-container>
        </ng-container>
    </div>




<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
    <p-toast />
    <p-confirmDialog />
  
    <!-- <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
        styleClass="p-button-success"
        [disabled]="!creaProgettoForm.valid"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
        styleClass="p-button-warning"></p-button> -->
   <p-button icon="pi pi-arrow-left" class="p-button-text mr-2" (click)="activeIndex = activeIndex - 1" [disabled]="activeIndex === 0 "></p-button>
   <p-button icon="pi pi-arrow-right" class="p-button-text mr-1" (click)="changePage()" [disabled]="checkNextStep()"></p-button>
   <p-button *ngIf="activeIndex === pagineCreaProgetto.length -1" icon="pi pi-check" label="Conferma"
    class="p-button-text ml-100" styleClass="p-button-success" (click)="conferma(true)" [disabled]="isLoading"></p-button>
</div>
