import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { Azienda } from '../models/fragile/azienda.model';
import { Progetto } from '../models/fragile/progetto.model';


@Injectable({
  providedIn: 'root',
})
export class ProgettiService{
  apiUrl: string = environment.apiUrl +"progetto";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}


  crea(progetto:Progetto): Observable<any> {
    const formdata = new FormData();
    formdata.append("identificativo", progetto.identificativo.valueOf());
    formdata.append("descrizione", progetto.descrizione?progetto.descrizione.valueOf():'');
    formdata.append("idAzienda", progetto.idAzienda.valueOf());
    if(progetto.fileMisurazioni!=null){
      formdata.append("fileMisurazioni", progetto.fileMisurazioni, progetto.fileMisurazioni.name);
      formdata.append("separatoreCSV", progetto.separatoreCSV.valueOf());

    }
    if(progetto.fileMisurazioniASC!=null){
      formdata.append("fileMisurazioniASC", progetto.fileMisurazioniASC, progetto.fileMisurazioniASC.name);
      formdata.append("separatoreCSVASC", progetto.separatoreCSVASC.valueOf());

    }
    if(progetto.fileMisurazioniDESC!=null){
      formdata.append("fileMisurazioniDESC", progetto.fileMisurazioniDESC, progetto.fileMisurazioniDESC.name);
      formdata.append("separatoreCSDESC", progetto.separatoreCSVDESC.valueOf());

    }
    if(progetto.fileMisurazioniVERTICALE!=null){
      formdata.append("fileMisurazioniVERTICALE", progetto.fileMisurazioniVERTICALE, progetto.fileMisurazioniVERTICALE.name);
      formdata.append("separatoreCSVERTICALE", progetto.separatoreCSVVERTICAL.valueOf());
    }
    formdata.append("fileQGis", progetto.fileQGis, progetto.identificativo+"qgs");
    formdata.append("fileGeoPackage", progetto.fileGeoPackage, progetto.fileGeoPackage.name);
    formdata.append("nomeFileGeoPackage", progetto.fileGeoPackage.name);
    //formdata.append("fileGeoPackage", progetto.fileGeoPackage, progetto.identificativo+"gpkg");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, formdata, httpOptions);

  }

  modifica(progetto:Progetto): Observable<any> {
    const formdata = new FormData();
    if(progetto.identificativo){
      formdata.append("identificativo", progetto.identificativo.valueOf());
    }
    if(progetto.descrizione){
      formdata.append("descrizione", progetto.descrizione?progetto.descrizione.valueOf():'');
    }
    if(progetto.idAzienda){
      formdata.append("idAzienda", progetto.idAzienda.valueOf());
    }
    if(progetto.fileMisurazioni!=null){
      formdata.append("fileMisurazioni", progetto.fileMisurazioni, progetto.fileMisurazioni.name);
    }
    if(progetto.fileQGis!=null){
      formdata.append("fileQGis", progetto.fileQGis, progetto.identificativo+"qgs");
    }
    if(progetto.fileGeoPackage!=null){
      this.logger.info("--> modifico progetto con geopackage chiamato "+ progetto.fileGeoPackage.name);
      formdata.append("fileGeoPackage", progetto.fileGeoPackage, progetto.fileGeoPackage.name);

    }
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(this.apiUrl+"/"+progetto.id, formdata, httpOptions);

  }

  caricaMisurazioni(progetto:Progetto, isOverwrite:string, layer:string, separatore:string): Observable<any> {
    const formdata = new FormData();
    if(layer==='ASC'){
      formdata.append("fileMisurazioniASC", progetto.fileMisurazioni, progetto.fileMisurazioni.name);

    }else if(layer==='DESC'){
      formdata.append("fileMisurazioniDESC", progetto.fileMisurazioni, progetto.fileMisurazioni.name);

    }else if(layer==='VERTICALE'){
      formdata.append("fileMisurazioniVERTICALE", progetto.fileMisurazioni, progetto.fileMisurazioni.name);

    }
    formdata.append("overwrite", isOverwrite);
    formdata.append("separatoreCSV", separatore);

    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl+"/"+progetto.id+"/carica-misurazioni?layer="+layer,formdata, httpOptions);
  }
  caricaPunti(progetto:Progetto, layer:string, separatore:string): Observable<any> {
    const formdata = new FormData();
    if(layer==='ASC'){
      formdata.append("fileMisurazioniASC", progetto.fileMisurazioni, progetto.fileMisurazioni.name);

    }else if(layer==='DESC'){
      formdata.append("fileMisurazioniDESC", progetto.fileMisurazioni, progetto.fileMisurazioni.name);

    }else if(layer==='VERTICALE'){
      formdata.append("fileMisurazioniVERTICALE", progetto.fileMisurazioni, progetto.fileMisurazioni.name);

    }
    formdata.append("separatoreCSV", separatore);

    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl+"/"+progetto.id+"/carica-punti?layer="+layer,formdata, httpOptions);
  }

  eliminaMisurazioni(idProgetto:number, layer:string): Observable<any> {
    const formdata = new FormData();
   
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl+"/"+idProgetto+"/elimina-misurazioni?layer="+layer, httpOptions);
  }

  getStoricoCaricamenti(idProgetto:Number, layer:string): Observable<any> {
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl+"/"+idProgetto+"/storico-caricamenti?layer="+layer, httpOptions);
  }



  elimina(idProgetto:Number): Observable<any> {
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(this.apiUrl+"/"+idProgetto, httpOptions);
  }




}
