

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, MessageService, PrimeNGConfig } from "primeng/api";

import * as Papa from 'papaparse';

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { UtentiService } from "src/app/services/utenti.service";
import { Azienda } from "../../../models/fragile/azienda.model";
import { AziendeService } from "src/app/services/aziende.service";
import { UtilService } from "src/app/services/util.service";
import { Progetto } from "src/app/models/fragile/progetto.model";
import { ProgettiService } from "src/app/services/progetti.service";
import { noSpacesValidator } from "src/app/shared/no-space.validator";
import { Progetto_Firestore } from "src/app/models/firestore/progettoFirestore.model";
import { UploadEvent } from "primeng/fileupload";


@Component({
  templateUrl: "./carica-csv.component.html",
  styleUrls: ["../home.component.css"],
  providers: [MessageService]
})
export class CaricaFileCSVComponent implements OnInit {
  protected _onDestroy         = new Subject<void>();

  protected csvFile: File;
  protected isFileUploaded: boolean = false;
  protected isFileSizeOvero50MB: boolean = false;
  protected fileSizeInMB: number = 0;
  protected isLoadingFile: boolean = false;
  protected isOverwrite: boolean = false;
  protected isCaricaPunti: boolean = false;
  protected progetto:Progetto_Firestore;
  protected label:string="Cerca file Misurazioni";
  protected icona:string="";
  protected stile:string="btn-choose-misurazioniFile";
  protected CSVclass:string="";
  protected layer:string="";
  protected separatore:string;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private aziendeService: AziendeService,
    private progettiService: ProgettiService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private primeConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService
  ) {
    this.progetto = this.config.data.progetto;
    this.isOverwrite = this.config.data.isOverwrite;
    this.layer = this.config.data.layer;
    this.isCaricaPunti = this.config.data.isPunti;
    
    if(this.layer==='ASC'){
      this.label += ' del layer ASC';
      this.icona = 'pi pi-arrow-up';
      this.CSVclass = 'btn-choose-misurazioniFile';
    }else if(this.layer==='DESC'){
      this.label += ' del layer DESC';
      this.icona = 'pi pi-arrow-down';
      this.CSVclass = 'btn-choose-misurazioniFileDesc';
    }else if(this.layer==='VERTICALE'){
      this.label += ' del layer VERTICALE';
      this.icona = 'pi pi-arrows-v';
      this.CSVclass = 'btn-choose-misurazioniFileVerticale';
    }
    
  }

  ngOnInit(): void {
   
  }

  detectDelimiter(csvData: string): string {
    const delimiters = [',', ';', '\t'];
    let bestDelimiter = ',';
    let maxColumns = 0;

    delimiters.forEach((delimiter) => {
      const parsed = Papa.parse(csvData, { delimiter, preview: 1 });
      const columnsCount = parsed.data[0]?.length || 0;

      if (columnsCount > maxColumns) {
        maxColumns = columnsCount;
        bestDelimiter = delimiter;
      }
    });

    return bestDelimiter;
  }
  
  onCSVFileSelect($event: { files: File[]; }) {
   
    this.csvFile = $event.files[0];
    this.logger.info(this.csvFile);
    let misurazioni = 0;
    this.fileSizeInMB =  Math.floor(this.csvFile.size / (1024 * 1024));
    this.isLoadingFile= true;
    if (this.fileSizeInMB > 50) {
      this.isFileSizeOvero50MB = true;
      let numberOfLines       = 0;
      let firstLineProcessed  = false;

      Papa.parse(this.csvFile, {
        worker: true, // Usa un worker per evitare blocchi
        step: (row) => {
          numberOfLines++;
          //this.logger.info(">" + numberOfLines);
          // Controlla la prima riga per determinare il separatore
          if (!firstLineProcessed) {
            firstLineProcessed = true;
            const firstLine = row.data[0];
            const pattern = /^d\d{8}$/i;
            misurazioni = row.data.filter(item => pattern.test(item)).length;
            this.separatore = row.meta.delimiter === ',' ? 'virgola' : 'punto_virgola';
          }
          },
          complete: () => {
            this.label = 'Il file contiene ' + (numberOfLines - 1) + ' record e '+ misurazioni+' misurazioni';
            this.icona = "pi pi-check";
            this.CSVclass = "btn-desc-upload-success";
            this.isFileUploaded = true;
            this.isLoadingFile= false;
          },
          error: (error) => {
            console.error("Errore durante il parsing del file CSV:", error);
          }
        });

        }else{
          const reader = new FileReader();
          reader.readAsText(this.csvFile);
          reader.onload = (e: any) => {
            const fileContent = e.target.result; // Contenuto del file CSV come stringa
            const lines = fileContent.split('\n'); // Divide il contenuto in righe
            const numberOfLines = lines.length;
            const firstLine = lines[0];
            const pattern = /[dD]\d{8}/g;  // Pattern che cerca "d" o "D" seguito da 8 cifre
            const matches = firstLine.match(pattern);  // Trova tutte le occorrenze
            misurazioni = matches ? matches.length : 0;

            this.icona = "pi pi-check";
            this.label  = 'Il file contiene ' + (numberOfLines-1) + ' record e '+ misurazioni+' misurazioni' ; //+ " ("+this.formatSize(this.misurazioniDESCFile.size) +")"
            this.CSVclass  = "btn-desc-upload-success";
            this.isFileUploaded = true;
            this.isLoadingFile= false;
            // Rileva il separatore dalla prima riga del file CSV
           
            const delimiters = [',', ';', '\t'];
            let detectedSeparator = ',';
            let maxOccurrences = 0;
            delimiters.forEach(delimiter => {
              const occurrences = firstLine.split(delimiter).length - 1;
              if (occurrences > maxOccurrences) {
                maxOccurrences = occurrences;
                detectedSeparator = delimiter;
              }
            });
            this.separatore = detectedSeparator===','?'virgola':'punto_virgola';
            console.log(`Separatore rilevato: ${detectedSeparator}`); 
            
          }
        }

  }

  onCSVFileUpload($event: { files: File[]; }) {
  }

  formatSize(bytes) {
    const k = 1024;
    const dm = 3;
    const sizes = this.primeConfig.translation.fileSizeTypes;
    if (bytes === 0) {
        return `0 ${sizes[0]}`;
    }
  
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  
    return `${formattedSize} ${sizes[i]}`;
  }
  

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      if(this.isFileSizeOvero50MB){
        this.confirmationService.confirm({
          target: event.target as EventTarget,
          message: 'Il file ha una dimensione di <strong>'+this.fileSizeInMB + ' MB</strong>.<br> La sua elaborazione richiederà anche il tempo necessario al suo caricamento sul server.<br> <strong>Procedere comunque?</strong>',
          header: 'Attenzione',
          icon: 'pi pi-exclamation-triangle',
        /*   acceptIcon:"pi pi-check",
          rejectIcon:"pi pi-times", */
          rejectButtonStyleClass:"reject-fileupload-btn",
          acceptButtonStyleClass:"accept-fileupload-btn",
          acceptLabel:"SI'",
          rejectLabel:"NO",
          accept: () => {
            let progettoDaModificare: Progetto = new Progetto();
            progettoDaModificare.id = this.progetto.id;
            progettoDaModificare.fileMisurazioni      = this.csvFile;
            this.logger.info(progettoDaModificare);
            this.isLoadingFile=true;
            if(!this.isCaricaPunti){
                this.progettiService.caricaMisurazioni(progettoDaModificare, this.isOverwrite?'true':'false', this.layer, this.separatore).subscribe( {
                  next: (resCreaProgetto) => {
                    this.logger.error(resCreaProgetto);
                    this.isLoadingFile=false;
                    this.dialogRef.close({ success: true });
                  },
                  error: (e) => {
                      this.isLoadingFile=false;
                      this.dialogRef.close({ success: false });
                      this.messageService.add({
                        severity: "error",
                        summary: "ATTENZIONE",
                        detail: "Errore in fase di caricamento del file delle misurazioni per il layer "+ this.layer + " del progetto!", life:10000
                      });
                },
                  complete: () => {
      
                  }
                });
            }else{
              this.progettiService.caricaPunti(progettoDaModificare, this.layer, this.separatore).subscribe( {
                next: (resCreaProgetto) => {
                  this.logger.error(resCreaProgetto);
                  this.isLoadingFile=false;
                  this.dialogRef.close({ success: true });
                },
                error: (e) => {
                    this.isLoadingFile=false;
                    this.dialogRef.close({ success: false });
                    this.messageService.add({
                      severity: "error",
                      summary: "ATTENZIONE",
                      detail: "Errore in fase di caricamento del file delle misurazioni per il layer "+ this.layer + " del progetto!", life:10000
                    });
              },
                complete: () => {
      
                }
              });
            } 
          },
          reject: () => {
            this.dialogRef.close({ success: false });
          }
        });
      }else{
        let progettoDaModificare: Progetto = new Progetto();
        progettoDaModificare.id = this.progetto.id;
        progettoDaModificare.fileMisurazioni      = this.csvFile;
        this.logger.info(progettoDaModificare);
        this.isLoadingFile=true;
        if(!this.isCaricaPunti){
            this.progettiService.caricaMisurazioni(progettoDaModificare, this.isOverwrite?'true':'false', this.layer, this.separatore).subscribe( {
              next: (resCreaProgetto) => {
                this.logger.error(resCreaProgetto);
                this.isLoadingFile=false;
                this.dialogRef.close({ success: true });
              },
              error: (e) => {
                  this.isLoadingFile=false;
                  this.dialogRef.close({ success: false });
                  this.messageService.add({
                    severity: "error",
                    summary: "ATTENZIONE",
                    detail: "Errore in fase di caricamento del file delle misurazioni per il layer "+ this.layer + " del progetto!", life:10000
                  });
            },
              complete: () => {

              }
            });
        }else{
          this.progettiService.caricaPunti(progettoDaModificare, this.layer, this.separatore).subscribe( {
            next: (resCreaProgetto) => {
              this.logger.error(resCreaProgetto);
              this.isLoadingFile=false;
              this.dialogRef.close({ success: true });
            },
            error: (e) => {
                this.isLoadingFile=false;
                this.dialogRef.close({ success: false });
                this.messageService.add({
                  severity: "error",
                  summary: "ATTENZIONE",
                  detail: "Errore in fase di caricamento del file delle misurazioni per il layer "+ this.layer + " del progetto!", life:10000
                });
          },
            complete: () => {

            }
          });
        } 
      }
      
      /* 
     */
    }
  }
}
