

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { Azienda } from "src/app/models/fragile/azienda.model";
import { Utente } from "src/app/models/fragile/utente.model";
import { RuoloUtente } from "src/app/models/fragile/utente.ruolo.model";
import { AziendeService } from "src/app/services/aziende.service";

import { UtentiService } from "src/app/services/utenti.service";
import { UtilService } from "src/app/services/util.service";


@Component({
  templateUrl: "./dialog-modifica-utente.component.html",
  styleUrls: ["../utenti.component.css"],
})
export class ModificaUtenteComponent implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected ruoli: RuoloUtente[];
  protected aziende: Azienda[]= [];

  public modificaUtenteForm: FormGroup;
  public utenteSelezionato: Utente;
  public isRuoloUtente: Boolean = false;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private utentiService: UtentiService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private utilService:UtilService,
    private aziendeService:AziendeService,


  ) {
    this.loadRuoliUtente();
    this.loadAziende();
  }

  ngOnInit(): void {
    this.utenteSelezionato = this.config.data.utente;
    
    if(this.utenteSelezionato.ruolo.codice === 'UTENTE'){
      this.isRuoloUtente = true;
      
    }
    this.modificaUtenteForm = this._formBuilder.group({
      nome:           [this.utenteSelezionato.nome, [Validators.required]],
      ruolo:          [this.utenteSelezionato.ruolo, [Validators.required]],
      azienda:        [this.utenteSelezionato.azienda]
    });


  }

  loadRuoliUtente(){
    this.utilService.getRuoliUtente().subscribe(ruoliFromDB=>{
      this.ruoli = ruoliFromDB;
    });
  }

  loadAziende(){
    this.aziendeService.get().subscribe(aziendeFromDB=>{
      this.aziende = aziendeFromDB;
    });
  }
 
  getRuoloClass(ruolo: string): string {
    if (ruolo === "ADMIN") {
      return "ruolo ruolo-admin";
    }else if (ruolo === "UTENTE") {
      return "ruolo ruolo-utente";
    }
    return "";
  }

  onRuoloSelection(event) {
    let ruoloSelezionato:RuoloUtente = event.value;
    if(ruoloSelezionato.codice==="UTENTE"){
      this.isRuoloUtente = true;
    }else{
      this.isRuoloUtente = false;
      this.modificaUtenteForm.value.azienda = null;
    }
   }



  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let utenteDaModificare: Utente = new Utente();
      utenteDaModificare.id = this.utenteSelezionato.id;
      utenteDaModificare.nome = this.modificaUtenteForm.value.nome;
      utenteDaModificare.ruolo = this.modificaUtenteForm.value.ruolo;
      if(this.modificaUtenteForm.value.azienda){
        utenteDaModificare.azienda = this.modificaUtenteForm.value.azienda;
      }
      this.utentiService.modifica(utenteDaModificare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          console.error("ERRORE MODIFICA UTENTE" + error);
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di modifica utente!", life:10000
          });
        }
      );
    }
  }
}
