import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';


@Injectable({
  providedIn: 'root',
})
export class SupportService{

  constructor(
    private http: HttpClient,
    private logger: NGXLogger
    ) {}


  async waitForStorageValue(key: string): Promise<any> {

    return new Promise((resolve) => {
      const checkInterval = setInterval(() => {
        const storedValue = localStorage.getItem(key);

        if (storedValue !== null) {
          clearInterval(checkInterval);
          resolve(storedValue);
        }
      }, 500);
    });
  }



}
