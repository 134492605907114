

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, MessageService, PrimeNGConfig } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { UtentiService } from "src/app/services/utenti.service";
import { Azienda } from "../../../models/fragile/azienda.model";
import { AziendeService } from "src/app/services/aziende.service";
import { UtilService } from "src/app/services/util.service";
import { Progetto } from "src/app/models/fragile/progetto.model";
import { ProgettiService } from "src/app/services/progetti.service";
import { noSpacesValidator } from "src/app/shared/no-space.validator";
import { Progetto_Firestore } from "src/app/models/firestore/progettoFirestore.model";
import { UploadEvent } from "primeng/fileupload";


@Component({
  templateUrl: "./carica-qgis.component.html",
  styleUrls: ["../home.component.css"],
  providers: [MessageService]
})
export class CaricaFileQGIComponent implements OnInit {
  protected _onDestroy         = new Subject<void>();

  protected qgisFile: File;
  protected isFileUploaded: boolean = false;
  protected isLoadingFile: boolean = false;
  protected progetto:Progetto_Firestore;
  protected label:string="Cerca file QGIS";
  protected icona:string="pi pi-map-marker";
  protected stile:string="btn-choose-qgisFile";
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private aziendeService: AziendeService,
    private progettiService: ProgettiService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private primeConfig: PrimeNGConfig,
  ) {
    this.progetto = this.config.data.progetto;
  }

  ngOnInit(): void {
   
  }

 
  
  onQGISFileSelect($event: { files: File[]; }) {
    this.isLoadingFile= true;
  }
  onQGISFileUpload($event: { files: File[]; }) {
    this.qgisFile = $event.files[0];
    this.label=this.qgisFile.name + " ("+this.formatSize(this.qgisFile.size) +")";
    this.isLoadingFile= false;
    this.isFileUploaded = true;
  }

  formatSize(bytes) {
    const k = 1024;
    const dm = 3;
    const sizes = this.primeConfig.translation.fileSizeTypes;
    if (bytes === 0) {
        return `0 ${sizes[0]}`;
    }
  
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  
    return `${formattedSize} ${sizes[i]}`;
  }
  

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let progettoDaModificare: Progetto = new Progetto();
      progettoDaModificare.id = this.progetto.id;
      progettoDaModificare.fileQGis      = this.qgisFile;
      this.logger.info(progettoDaModificare);
      this.isLoadingFile=true;
      this.progettiService.modifica(progettoDaModificare).subscribe( {
          next: (resCreaProgetto) => {
            this.logger.error(resCreaProgetto);
            this.isLoadingFile=false;
            this.dialogRef.close({ success: true });
          },
          error: (e) => {
              this.logger.error("ERRORE CARICAMENTO FILE QGIS DEL PROGETTO" + e);
              this.isLoadingFile=false;
              this.dialogRef.close({ success: false });
              this.messageService.add({
                severity: "error",
                summary: "ATTENZIONE",
                detail: "Errore in fase di caricamento del file QGIS del progetto!", life:10000
              });
        },
          complete: () => {

            //this.isLoadingResults = false;
          }
      });
    }
  }
}
