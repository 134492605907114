
export class StatoCaricamento {
    id: Number;
    label: String;
  }

export enum StatoCaricamentoEnum {
  IN_CORSO  = "IN CORSO",
  SOSPESO   = "SOSPESO",
  IN_ERRORE = "IN ERRORE",
  CONCLUSO  = "CONCLUSO",
  ELIMINATO  = "ELIMINATO"
}