<div class="surface-card p-4 shadow-2 border-round">
    <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
     <div class="flex justify-start md:justify-start">
        <span class="p-input-icon-left mr-4">
            <i class="pi pi-search"></i>
            <input type="text" pInputText placeholder="Cerca"
              (input)="filtraAziende($event)" [disabled]="isLoadingResults" />
          </span>
       
      </div>
      <div class="flex justify-end md:justify-center">
        <span class="text-xl font-medium text-2000 font-['Poppins']" style="color: #6c092c">AZIENDE</span>
      </div>
      
     <div class="flex justify-end md:justify-end">
       
       <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(175, 209, 175); color: white;" (click)="loadAziende()"></button>
       <button 
          pButton type="button" icon="pi pi-plus"
          pTooltip="Nuova Azienda"
          class="inline-flex justify-content-center align-items-center mb-1 mr-1"
          style="background-color:#CBEFFF ; color: #007bff; border-color: #CBEFFF; font-family:'Poppins'"
          tooltipPosition="top"
          label="Nuova"
          (click)="openCreaAziendaDialog()" 
          [disabled]="isLoadingResults">
       </button>
       
     </div>
 </div>
            <div fxFlex="95%" class="mt-2">
                <p-table #aziendeDT styleClass="p-datatable-sm" [value]="aziende" dataKey="id" [(selection)]="selectedAzienda"
                [selectionPageOnly]="true" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[10,25,50]" responsiveLayout="scroll" [paginator]="true"
                currentPageReportTemplate="{first} - {last} di {totalRecords}" [filterDelay]="0"
                [globalFilterFields]="['ragioneSociale']"
                [loading]="isLoadingResults"
                [sortField]="'ragioneSociale'" [sortOrder]="1">

                <ng-template pTemplate="header">
                    <tr>

                        <th pSortableColumn="ragioneSociale" style="font-size: 14px;">
                            <div class="flex justify-content-between align-items-center">
                              Ragione Sociale
                              <p-sortIcon field="ragioneSociale"></p-sortIcon>
                            </div>
                          </th>
                          <th pSortableColumn="progetti" style="font-size: 14px;">
                            <div class="flex justify-content-between align-items-center" class="text-center" >
                              # PROGETTI
                              <p-sortIcon field="progetti"></p-sortIcon>
                            </div>
                          </th>
                          <th pSortableColumn="utenti"  style="font-size: 14px;">
                            <div class="flex justify-content-between align-items-center" class="text-center" >
                              # UTENTI
                              <p-sortIcon field="utenti"></p-sortIcon>
                            </div>
                          </th>
                         <!--  <th pSortableColumn="mail">
                            <div class="flex justify-content-between align-items-center">
                              Mail
                              <p-sortIcon field="mail"></p-sortIcon>
                            </div>
                          </th>

                          <th pSortableColumn="ruolo.codice" class="text-center">
                              Ruolo
                              <p-sortIcon field="ruolo.codice"></p-sortIcon>
                          </th>
                          <th pSortableColumn="azienda.ragioneSociale" class="text-center">
                            Azienda
                            <p-sortIcon field="azienda.ragioneSociale"></p-sortIcon>
                        </th> -->
                   <th class="text-center" style="font-size: 14px;">Azioni</th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-row>
                    <tr>
                        <td> 
                          <p-tag
                              [value]="row.ragioneSociale | uppercase"
                              [style]="{
                                'background-color': row.colore ,
                                'color': '#ffffff' ,
                                'border-radius': '6px',
                                'padding': '.25em .5rem',
                                'font-weight': '700',
                                'font-size': '13px',
                                'letter-spacing': '.3px;',
                                'cursor': 'pointer',
                              }"

                            class="mr-1"
                        ></p-tag>
                          <!-- <span class="text-uppercase" style="font-weight: 500; font-weight: bold;">{{row.ragioneSociale | uppercase}}</span> -->
                        </td>
                        <td class="text-center"> <span class="text-uppercase" style="font-weight: 500; font-weight: bold;">{{row.numeroProgetti}}</span> </td>
                        <td class="text-center"> <span class="text-uppercase" style="font-weight: 500; font-weight: bold;">{{row.numeroUtenti}}</span> </td>
                        <!-- <td> <span class="text-lowercase">{{row.mail}}</span> </td>
                        <td class="text-center"> <span class="text-lowercase" [ngClass]="getRuoloClass(row.ruolo.codice)">{{row.ruolo?row.ruolo.codice:''}}</span> </td>
                        <td class="text-center"> <span class="text-uppercase" [ngClass]="row.azienda?getAziendaClass(row.azienda):''" style="font-weight: bold; font-size: 12px;" >{{row.azienda?(row.azienda.ragioneSociale | uppercase):''}}</span> </td>
 -->
                       <td class="flex justify-content-center align-items-center" >
                        <button pButton type="button" style="color:rgb(4, 61, 6);" icon="pi pi-chart-line" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Vai ai progetti"
                            (click)="openProgettiAzienda(row)" >
                        </button>
                        <button pButton type="button" style="color:rgb(36, 18, 92);" icon="pi pi-users" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Vai agli utenti"
                            (click)="openUtentiAzienda(row)" >
                        </button>
                        <button pButton type="button" style="color:rgb(44, 113, 144);" icon="pi pi-user-edit" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Modifica Azienda"
                            (click)="openModificaAziendaDialog(row)"
                            
                            >
                        </button>
                        <button pButton pRipple type="button" icon="pi pi-trash"
                            class="p-button-rounded p-button-text"
                            (click)="openDeleteAzienda(row)"
                            style="color:red;" tooltipPosition="top" pTooltip="Elimina Azienda"
                            [disabled]="row.id==1 || row.numeroProgetti > 0 || row.numeroUtenti > 0"
                            >
                        </button>
                        </td>
                      </tr>
                </ng-template>
              </p-table>
            </div>
		</div>

<p-dialog [(visible)]="showDeleteConfirmation" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}" [closable]="false" [showHeader]="false">
    <div class="flex flex-column align-items-center my-4">
        <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3" style="width:64px;height:64px">
            <i class="pi pi-trash text-5xl"></i>
        </span>
        <div class="font-medium text-2xl text-900">ELIMINAZIONE</div>
    </div>
    <p class="line-height-3 p-0 m-0">
       Confermi di voler eliminare l'azienda <span style="font-weight: bold;">{{selectedAzienda?selectedAzienda.ragioneSociale.toUpperCase():''}}</span>?
    </p>
    <ng-template pTemplate="footer">
        <div class=" border-top-1 surface-border pt-3 flex">
            <button pButton pRipple icon="pi pi-times" styleClass="p-button-success" (click)="showDeleteConfirmation = false" label="ANNULLA" class="p-button-outlined w-6 mr-2"></button>
            <button pButton pRipple icon="pi pi-check" styleClass="p-button-warning" (click)="confermaEliminazioneAzienda()" label="CONFERMA" class="w-6 ml-2"></button>
        </div>
    </ng-template>
</p-dialog>


<p-toast position="top-center"></p-toast>
