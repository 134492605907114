import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";

import {DOCUMENT} from "@angular/common";
import {NGXLogger} from "ngx-logger";
import {TabViewChangeEvent} from "primeng/tabview";
import {environment} from "../../../environments/environment";
import {AngularFirestore, AngularFirestoreCollection} from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { Timestamp, onSnapshot } from "firebase/firestore";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmationService, MessageService } from "primeng/api";
import { Misurazione_Firestore } from "src/app/models/firestore/misurazioneFirestore.model";
import { ChartModule } from 'primeng/chart';
import { jwtDecode } from "jwt-decode";
import { JwtHelperService } from '@auth0/angular-jwt';
import { SecurityService } from "src/app/services/security.service";
import { Chart } from 'chart.js'; // Assicurati di importare Chart.js

@Component({
  templateUrl: "./misurazioni.component.html",
  styleUrls: ["./misurazioni.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class MisurazioniComponent implements OnInit, AfterViewInit {
 
  protected isLoading: boolean = true;
  public isReady: boolean = false;
  protected showLine: boolean = false;
  protected tokenValid: boolean = false;

  protected idProgetto:number;
  protected idPunto:number;
  protected layer:string;
  protected misurazione:Misurazione_Firestore;
  public misurazioni_punto:Misurazione_Firestore;

  ref: DynamicDialogRef;
  options: any;
  data: any;

  @ViewChild('chart') chart: any;

  constructor(
    private logger: NGXLogger,
    private firestore: AngularFirestore,
    private router: Router,
    public dialogService: DialogService,
    private messageService:MessageService,
    private activatedRoute: ActivatedRoute,
    private securityService:SecurityService
  ) {
   
  }

  ngOnInit() {
    this.logger.info("INIT @ MISURAZIONI");
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.options = {
      /* legend: {
        display: false       
      },
      title: {
        display: true,
        text: ''
      }, */
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {display:false} 
          /* legend: {
              labels: {
                  color: textColor
              }
          } */
      },
      scales: {
          x: {
              ticks: {
                  color: textColorSecondary
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              }
          },
          y: {
              ticks: {
                  color: textColorSecondary
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              }
          }
      }
  };
  
  const urlParamIDPunto       = this.activatedRoute.snapshot.paramMap.get('idPunto');
  const urlParamIDProgetto    = this.activatedRoute.snapshot.paramMap.get('idProgetto');
  const urlParamLayer         = this.activatedRoute.snapshot.paramMap.get('layer');
  if(urlParamIDProgetto!=null){
    this.idProgetto = Number(urlParamIDProgetto);
  }
  if(urlParamIDPunto!=null){
    this.idPunto = Number(urlParamIDPunto);
  }
  if(urlParamLayer!=null){
    this.layer = urlParamLayer;
  }
   this.logger.info("progetto "+ this.idProgetto);
   this.logger.info("punto "+ this.idPunto);
   this.logger.info("layer "+ this.layer);

  this.activatedRoute.queryParams.subscribe(params => {
    this.logger.info( params);
   /*  this.tokenValid = true;
    this.loadMisurazioniPuntoLayer(this.idProgetto, this.idPunto, (this.layer.endsWith('asc')||this.layer.endsWith('ASC'))?'ASC':(this.layer.endsWith('desc')||this.layer.endsWith('DESC'))?'DESC':'VERTICALE');
   */
    const token = params['token'];
    if (token) {
      this.validateToken(token);
    }   
   /* this.tokenValid = true;
      this.loadMisurazioniPunto(this.idProgetto, this.idPunto); */
    this.showLine = params['showline'] === 'true';
  });

  //https://measura.map-hosting.it/misurazione/13/200?showline=true%3EApri
  }

  

   validateToken(token:String): void {
    //this.logger.info("parte valida token "+ token);

    this.securityService.validateToken(token).subscribe({
      next: (res) => {
        this.tokenValid = true;
        this.logger.info("OK VALIDAZIONE TOKEN PER PUNTO > "+ this.idPunto + " DEL PROGETTO "+ this.idProgetto + " SU LAYER "+ this.layer);
        this.loadMisurazioniPuntoLayer(this.idProgetto, this.idPunto, this.layer);
        
      },
      error: (error) => {
        if (error.status === 401) {
          this.logger.error("Unauthorized: Token non valido o scaduto.");
        } else {
          this.logger.error("Errore nella chiamata HTTP:", error);
        }
      },
      complete: () => {
        this.logger.info("Chiamata completata.");
      }
    });
  } 


  ngAfterViewInit() {
  }

  setTimeRange(range: string) {
    const labels = Object.keys(this.misurazione.misurazioni).filter(key => key !== 'ID');
    const values = labels.map(label => this.misurazione.misurazioni[label]);
    const dateLabels = labels.map(label => { 
      return new Date(
      parseInt(label.substring(1, 5)), // Anno
      parseInt(label.substring(5, 7)) - 1, // Mese (zero-based)
      parseInt(label.substring(7,9)) // Giorno
    )});
    //dateLabels.sort((a, b) => a.getTime() - b.getTime());
    let filteredLabels: string[] = [];
    let filteredValues: number[] = [];

    const now = new Date();
    let startDate: Date;
    
    switch (range) {
      case '1M':
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        break;
      case '6M':
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case 'ALL':
       //this.loadMisurazioniPunto(this.idProgetto, this.idPunto);
       this.loadMisurazioniPuntoLayer(this.idProgetto, this.idPunto, this.layer);
       break;
    }
   
    
    for (let i = 0; i < dateLabels.length; i++) {
      
      if (dateLabels[i] >= startDate) {
        filteredLabels.push(labels[i]);
        filteredValues.push(values[i]);
      }
    }
    filteredLabels.sort((a, b) => {
      const dateA = parseInt(a.substring(1)); // Rimuovi la 'D' e converti in numero
      const dateB = parseInt(b.substring(1));
      return dateA - dateB; // Ordina in ordine crescente
    });
    //const values = labels.map(label => this.misurazione.misurazioni[label]);
    const formattedLabels = filteredLabels.map(label => {
      const year = label.substring(1, 5);
      const month = label.substring(5, 7);
      const day = label.substring(7, 9);
      return `${day}/${month}/${year.substring(2)}`; // Formato dd/mm/yy
    });
    
    this.data = {
      labels: formattedLabels,
      datasets: [
        {
          data: filteredValues,
          fill: false,
          borderColor: '#42A5F5',
          tension: 0.4,
          pointRadius: 3, // Dimensione dei punti
          showLine: this.showLine // Imposta come desiderato
        }
      ]
    };

    
  }

  downloadChart() {
    const chartInstance = this.chart.chart; // Ottieni l'istanza del grafico

    if (chartInstance) {
      const imageUrl = chartInstance.toBase64Image(); // Ottieni l'immagine in formato PNG
      const link = document.createElement('a'); // Crea un link per il download

      link.href = imageUrl; // Imposta il link all'immagine
      link.download = this.idProgetto+"_"+this.idPunto+'.png'; // Imposta il nome del file per il download
      link.click(); // Simula il click sul link per avviare il download
    } else {
      console.error("Errore: il grafico non è stato trovato.");
    }
  }

  /* loadMisurazioniPunto (idProgetto:number, idPunto:number) {
    this.logger.info("-> loadMisurazioniPunto "+ idProgetto + "/"+idPunto);
    const collectionRef = this.firestore.collection(environment.firebase.collectionMisurazioni).ref.where('idProgetto', "==", idProgetto).where('idPunto', "==", idPunto);
    let queryMisurazioni = collectionRef;

    onSnapshot(queryMisurazioni, (qs) => {

        qs.docs.map((item) => {

          this.misurazione = item.data() as Misurazione_Firestore;
      
          let labels = Object.keys(this.misurazione.misurazioni).filter(key => key !== '\"SPAZIO\"');
          labels.sort((a, b) => {
            const dateA = parseInt(a.substring(1)); // Rimuovi la 'D' e converti in numero
            const dateB = parseInt(b.substring(1));
            return dateA - dateB; // Ordina in ordine crescente
          });
          const values = labels.map(label => this.misurazione.misurazioni[label]);
          const formattedLabels = labels.map(label => {
            const year = label.substring(1, 5);
            const month = label.substring(5, 7);
            const day = label.substring(7, 9);
            return `${day}/${month}/${year.substring(2)}`; // Formato dd/mm/yy
          });
          
          //
          //const values = labels.map(label => this.misurazione.misurazioni[`D${label.substring(6, 8)}${label.substring(3, 5)}${label.substring(0, 2)}`]);
          this.data = {
            labels: formattedLabels,
            datasets: [
              {
                //label: 'Misurazioni',
                data: values,
                fill: false,
                borderColor: '#42A5F5',
                tension: 0.4,
                pointRadius: 3,      // Dimensione dei punti
                showLine: this.showLine  ,
    
              }
            ]  
          };  
       
         
      
      });
      
    });
  } */

  loadMisurazioniPuntoLayer (idProgetto:number, idPunto:number, layer:string) {
   this.logger.info("-> loadMisurazioniPunto "+ idProgetto + "_"+idPunto+ "_"+layer);
    let tripletta:string = idProgetto + "_"+idPunto+ "_"+layer.toUpperCase();
    const documentRef = this.firestore.collection(environment.firebase.collectionMisurazioni).doc(tripletta);
    documentRef.get().subscribe((docSnapshot) => {
      if (docSnapshot.exists) {
        this.misurazione = docSnapshot.data() as Misurazione_Firestore;
        this.logger.info("-> TROVATA MISURAZIONE PER PUNTO "+ idPunto + " DEL PROGETTO "+ idProgetto + " SU LAYER "+ layer.toUpperCase());
      
        let labels = Object.keys(this.misurazione.misurazioni).filter(key => key !== '\"SPAZIO\"' && key!=='lon'&& key!=='lat' );
        labels.sort((a, b) => {
          const dateA = parseInt(a.substring(1)); // Rimuovi la 'D' e converti in numero
          const dateB = parseInt(b.substring(1));
          return dateA - dateB; // Ordina in ordine crescente
        });
        const values = labels.map(label => this.misurazione.misurazioni[label]);
        const formattedLabels = labels.map(label => {
          const year = label.substring(1, 5);
          const month = label.substring(5, 7);
          const day = label.substring(7, 9);
          return `${day}/${month}/${year.substring(2)}`; // Formato dd/mm/yy
        });
        //this.logger.info(formattedLabels);
        this.data = {
          labels: formattedLabels,
          datasets: [
            {
              //label: 'Misurazioni',
              data: values,
              fill: false,
              borderColor: '#42A5F5',
              tension: 0.4,
              pointRadius: 3,      // Dimensione dei punti
              showLine: this.showLine  ,
  
            }
          ]  
        };  
      }else{
        this.logger.info("-> NESSUNA MISURAZIONE PER PUNTO "+ idPunto + " DEL PROGETTO "+ idProgetto + " SU LAYER "+ layer.toUpperCase());
      }
    });
    /* const collectionRef = this.firestore.collection(environment.firebase.collectionMisurazioni).ref.where('idProgetto', "==", idProgetto).where('idPunto', "==", idPunto);
    let queryMisurazioni = collectionRef;

    onSnapshot(queryMisurazioni, (qs) => {

        qs.docs.map((item) => {

          this.misurazione = item.data() as Misurazione_Firestore;
      
          let labels = Object.keys(this.misurazione.misurazioni).filter(key => key !== '\"SPAZIO\"');
          labels.sort((a, b) => {
            const dateA = parseInt(a.substring(1)); // Rimuovi la 'D' e converti in numero
            const dateB = parseInt(b.substring(1));
            return dateA - dateB; // Ordina in ordine crescente
          });
          const values = labels.map(label => this.misurazione.misurazioni[label]);
          const formattedLabels = labels.map(label => {
            const year = label.substring(1, 5);
            const month = label.substring(5, 7);
            const day = label.substring(7, 9);
            return `${day}/${month}/${year.substring(2)}`; // Formato dd/mm/yy
          });
          
          //
          //const values = labels.map(label => this.misurazione.misurazioni[`D${label.substring(6, 8)}${label.substring(3, 5)}${label.substring(0, 2)}`]);
          this.data = {
            labels: formattedLabels,
            datasets: [
              {
                //label: 'Misurazioni',
                data: values,
                fill: false,
                borderColor: '#42A5F5',
                tension: 0.4,
                pointRadius: 3,      // Dimensione dei punti
                showLine: this.showLine  ,
    
              }
            ]  
          };  
       
         
      
      }); 
      
    });*/
  }

}
