import { Injectable } from '@angular/core';
import { StatoCaricamentoEnum } from '../models/fragile/stato.caricamento.model';
import { StoricoCaricamento } from '../models/fragile/storico.caricamento.model';


@Injectable({
  providedIn: 'root',
})
export class StiliService{


  getMaterialIconStatoCaricamento(storicoCaricamento:StoricoCaricamento) : string{

    switch (storicoCaricamento.stato.label) {
      case StatoCaricamentoEnum.IN_CORSO:
        return 'pending';
      case StatoCaricamentoEnum.SOSPESO:
        return "back_hand";
      case StatoCaricamentoEnum.IN_ERRORE:
        return "error";
      case StatoCaricamentoEnum.CONCLUSO:
        return "task_alt";
      case StatoCaricamentoEnum.ELIMINATO:
        return "delete_forever";
      default:
        return "pending";
     
    }
  }

  getColoreIconaStatoCaricamento(storicoCaricamento:StoricoCaricamento) : string{
    switch (storicoCaricamento.stato.label) {
      case StatoCaricamentoEnum.IN_CORSO:
        return '#D7DC82';
      case StatoCaricamentoEnum.SOSPESO:
        return "#FB8B24";
      case StatoCaricamentoEnum.IN_ERRORE:
        return "#A499B3";
      case StatoCaricamentoEnum.CONCLUSO:
        return "#22D87A";
      case StatoCaricamentoEnum.ELIMINATO:
          return "#FC7A1E";
      default:
        return "#D7DC82";
     
    }
  }


}
