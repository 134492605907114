
export class Azienda {
    id: Number;
    ragioneSociale: String;
    colore: String;
    creata:Date;
    modificata:Date;
    numeroUtenti: Number;
    numeroProgetti: Number;
  }
