<div class="p-1 md:p-1 lg:p-1" *ngIf="tokenValid ; else elseBlock" >
    <p-chart #chart type="line" [data]="data" [options]="options" />
    <div class="button-container">
        <button pButton pRipple type="button" 
        class="p-button-rounded mr-2 p-button-outlined" 
        style="background-color: rgb(175, 209, 175); color: white;" 
        (click)="setTimeRange('1M')" label="1M" ></button> 
        <button pButton pRipple type="button" 
        class="p-button-rounded mr-2 p-button-outlined" 
        style="background-color: rgb(175, 209, 175); color: white;" 
        (click)="setTimeRange('6M')" label="6M" ></button> 
        <button pButton pRipple type="button" 
        class="p-button-rounded mr-2 p-button-outlined" 
        style="background-color: rgb(175, 209, 175); color: white;" 
        (click)="this.loadMisurazioniPuntoLayer(this.idProgetto, this.idPunto, this.layer)" label="ALL" ></button> 
        <button pButton pRipple icon="pi pi-cloud-download" type="button" 
        class="p-button-rounded mr-2 p-button-outlined" 
        style="background-color: rgb(25, 41, 79); color: white;" 
        (click)="downloadChart()" pTooltip="Scarica grafico"></button>
      </div>
</div>

<ng-template #elseBlock>
  <!-- <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100vh; text-align: center;">
    <span class="material-icons" style="font-size: 80px; color: red;">block</span>
    <h1>Errore 401:</h1>
    <p>Non sei autorizzato ad accedere a questa risorsa.</p>
  </div> -->
</ng-template>