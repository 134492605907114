
<ng-template [ngIf]="isLoadingFile">
      <app-carica-storico-loading></app-carica-storico-loading>
</ng-template>
    

<div class="mb-2 mb-3 col-12"  style="text-align: center; display: flex; justify-content: center; align-items: center;">
    <p-fileUpload 
        mode="basic" 
        name="caricaQGIS"
        multiple="false"
        maxFileSize="5000000"
        accept=".qgs"
        [auto]="true"
        chooseLabel="{{label}}"
        chooseIcon="{{icona}}"
        styleClass="btn-choose-qgisFile"
        (onUpload)="onQGISFileUpload($event)" 
        (onSelect)="onQGISFileSelect($event)" 
        url="https://www.primefaces.org/cdn/api/upload.php"
    >
    </p-fileUpload>
</div>
<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
        styleClass="p-button-success"
        [disabled]="!isFileUploaded"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
        styleClass="p-button-warning"></p-button> 
  
</div>
