import { Injectable } from '@angular/core';
import { getStorage, ref, getBlob, deleteObject, uploadBytes } from 'firebase/storage';
import 'firebase/storage';

@Injectable({
    providedIn: 'root',
  })
export class GoogleStorageService {


  async downloadFileFromGoogleStorage(fileName: string): Promise<void> {
    const storage = getStorage();

    const pathReference = ref(
        storage,
        fileName
      );
    getBlob(pathReference).then(ok => {
      console.log(">>>> OK DOWNLOAD FILE");

      return ok;
    }).catch(error => {
      console.log(">>>> KO DOWNLOAD FILE FILE");
    });
  }




}
