import {
  Component,
  OnInit,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService } from "primeng/api";


import {
  DialogService,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-crea-progetto-loading",
  templateUrl: "./crea-progetto-loading.component.html",
  styleUrls: ["../home.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class CreaProgettoLoadingComponent implements OnInit {
  protected isLoadingResults = false;
  ref: DynamicDialogRef;
  
  protected lottieConfig: object;
  private anim: any;


  constructor(
    private logger: NGXLogger,
   
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  
    this.lottieConfig = {
      path: '/assets/animations/measura-home.json', // Imposta il percorso del tuo file JSON
      autoplay: true,
      loop: true,
      renderer: 'canvas',
    };
  }

  ngOnInit(): void {
   

  }

  animationCreated(animation: any): void {
    this.anim = animation;
  }


}

