

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";

import * as Papa from 'papaparse';

import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { UtentiService } from "src/app/services/utenti.service";
import { Azienda } from "../../../models/fragile/azienda.model";
import { AziendeService } from "src/app/services/aziende.service";
import { UtilService } from "src/app/services/util.service";
import { Progetto } from "src/app/models/fragile/progetto.model";
import { ProgettiService } from "src/app/services/progetti.service";
import { noSpacesValidator } from "src/app/shared/no-space.validator";


@Component({
  templateUrl: "./dialog-crea-progetto.component.html",
  styleUrls: ["../home.component.css"],
})
export class CreaProgettoComponent implements OnInit {
  protected _onDestroy         = new Subject<void>();
  protected aziende: Azienda[]  = [];
  public creaProgettoForm: FormGroup;

  protected pagineCreaProgetto: MenuItem[];
  protected activeIndex: number = 0;
  
  protected selectedFiles: File[] = [];
  protected qgisFile: File;
  protected geoPackageFile: File;
  protected misurazioniFile: File;
  protected misurazioniFileList: File[];
  protected misurazioniASCFile: File;
  protected misurazioniDESCFile: File;
  protected misurazioniVERTICALEFile: File;

  protected isFileUploaded: boolean = false;
  protected isFileDESCUploaded: boolean = false;
  protected isFileASCUploaded: boolean = false;
  protected isFileVERTICALEUploaded: boolean = false;

  protected isCreatingProject: boolean = false;

  protected labelDESC:string="Cerca file CSV per layer DESC";
  protected iconaDESC:string="pi pi-database";
  protected classDESC:string="btn-choose-misurazioniFileDesc";

  protected labelASC:string="Cerca file CSV per layer ASC";
  protected iconaASC:string="pi pi-database";
  protected classASC:string="btn-choose-misurazioniFile";

  protected labelVERTICALE:string="Cerca file CSV per layer VERTICALE";
  protected iconaVERTICALE:string="pi pi-database";
  protected classVERTICALE:string="btn-choose-misurazioniFileVerticale";

  protected isLoadingFile: boolean = false;

  protected fileASCSuperiore50MB: boolean = false;
  protected fileDESCSuperiore50MB: boolean = false;
  protected fileVERTICALESuperiore50MB: boolean = false;
  protected fileASCSizeInMB: number = 0;
  protected fileDESCSizeInMB: number = 0;
  protected fileVERTICALESizeInMB: number = 0;

  protected separatoriOptions: any[] = [
      { label: ',', value: 'virgola' },
      { label: ';', value: 'punto_virgola' }
  ];

  protected separatoreASC:string;
  protected separatoreDESC:string;
  protected separatoreVERTICALE:string;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private aziendeService: AziendeService,
    private progettiService: ProgettiService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private _formBuilder: FormBuilder,
  ) {
    this.loadAziende();
  }

  ngOnInit(): void {
    this.creaProgettoForm = this._formBuilder.group({
      identificativo:     [null, [Validators.required, noSpacesValidator()]],
      azienda:            [null, [Validators.required]],
      descrizione:        [null],
      ascSelected:        [null],
      descSelected:        [null],
      verticaleSelected:        [null],
    });
    this.inizializzaSteps();
  }

  inizializzaSteps() {
    this.pagineCreaProgetto = [
      {
        label: "Dettagli del progetto",
      },
      {
        label: "File QGIS e GEOPACKAGE",
      },
      {
        label: "File CSV delle MISURAZIONI",
      }
    ];
    this.activeIndex = 0;
  }
  checkNextStep(): boolean {
    let disableNextStepButton = false;
    if (this.activeIndex === this.pagineCreaProgetto.length - 1) {
      disableNextStepButton = true;
    }/*  else if (this.activeIndex === 1) {
      disableNextStepButton = !(this.posizioneForm.get("coordinate")?.value);
    } */ else if (this.activeIndex === 0 && !this.creaProgettoForm.valid) {
      disableNextStepButton = true;
    }else if (this.activeIndex === 1 && (!this.geoPackageFile || !this.qgisFile ) ) {
      disableNextStepButton = true;
    }
    return disableNextStepButton;
  }
  changePage() {
    this.activeIndex = this.activeIndex + 1;
    
  }

  loadAziende(){
    this.logger.info("LOADING AZIENDE");
    this.aziendeService.get().subscribe( {
        next: (res) => {
            this.aziende = res;
        },
        error: (e) => {
            this.logger.info(e);
          this.messageService.add({life: 10000,severity:'error', summary:'Errore', detail:'Errore in fase di caricamento aziende'});
      },
        complete: () => {
        }
    });
}
  
onQGISFileUpload($event: { files: File[]; }) {
  this.qgisFile = $event.files[0];
}
onQGISFileRemove($event) {
  this.qgisFile = null;
}
onGeoPackageFileUpload($event) {
  this.geoPackageFile = $event.files[0];
}
onGeoPackageFileRemove($event) {
  this.geoPackageFile = null;
}
onMisurazioniFileUpload($event) {
  this.misurazioniFile = $event.files[0];
}
onFileMisurazioniRemove($event) {
  this.misurazioniFile = null;
}
onFileUpload($event, layer:String) {
  /* const file = $event.files[0]; 
  const reader = new FileReader();
  reader.readAsText(file);
  reader.onload = (e: any) => {
    console.log(`Numero di righe nel CSV: #1`);

    const fileContent = e.target.result; // Contenuto del file CSV come stringa
    const lines = fileContent.split('\n'); // Divide il contenuto in righe
    
    // Rileva il separatore dalla prima riga del file CSV
    const firstLine = lines[0];
    const delimiters = [',', ';', '\t'];
    let detectedSeparator = ',';
    let maxOccurrences = 0;

    delimiters.forEach(delimiter => {
      const occurrences = firstLine.split(delimiter).length - 1;
      if (occurrences > maxOccurrences) {
        maxOccurrences = occurrences;
        detectedSeparator = delimiter;
      }
    });
    
    console.log(`Separatore rilevato: ${detectedSeparator}`);
    
    const numberOfLines = lines.length;
    console.log(`Numero di righe nel CSV: ${numberOfLines}`);
    if (layer==='ASC'){
      this.isFileASCUploaded = true;
      this.separatoreASC = detectedSeparator===','?'virgola':'punto_virgola';
      this.iconaASC = "pi pi-check";
      this.misurazioniASCFile = $event.files[0];
      this.labelASC  = 'Il file ASC contiene ' + (numberOfLines-1) + ' record' ; //+ " ("+this.formatSize(this.misurazioniDESCFile.size) +")"
      this.classASC  = "btn-asc-upload-success";
      this.isCreatingProject= false;
    }else if (layer==='DESC'){
      this.separatoreDESC = detectedSeparator===','?'virgola':'punto_virgola';
      this.isFileDESCUploaded = true;
      this.iconaDESC = "pi pi-check";
      this.misurazioniDESCFile = $event.files[0];
      this.labelDESC  = 'Il file DESC contiene ' + (numberOfLines-1) + ' record' ; //+ " ("+this.formatSize(this.misurazioniDESCFile.size) +")"
      this.classDESC  = "btn-desc-upload-success";
      this.isCreatingProject= false;
      
    }else if (layer==='VERTICALE'){
      this.separatoreVERTICALE = detectedSeparator===','?'virgola':'punto_virgola';
      this.misurazioniVERTICALEFile = $event.files[0];
      this.isFileVERTICALEUploaded = true;
      this.iconaVERTICALE = "pi pi-check";
      this.labelVERTICALE  = 'Il file VERTICALE contiene ' + (numberOfLines-1) + ' record' ; //+ " ("+this.formatSize(this.misurazioniDESCFile.size) +")"
      this.classVERTICALE  = "btn-desc-upload-success";
      this.isCreatingProject= false;
    }
  };
 */
  
  //this.misurazioniFileList.push = $event.files[0];

}
/* formatSize(bytes) {
  const k = 1024;
  const dm = 3;
  const sizes = this.primeConfig.translation.fileSizeTypes;
  if (bytes === 0) {
      return `0 ${sizes[0]}`;
  }

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${formattedSize} ${sizes[i]}`;
} */
onFileSelect($event, layer:String) {
  this.misurazioniFile = null;
  let file = $event.files[0];
  let misurazioni = 0;
  const fileSizeInMB =  Math.floor(file.size / (1024 * 1024));
 
  this.isLoadingFile= true;
  this.isCreatingProject= true;
  if (fileSizeInMB > 50) {
    if (layer==='ASC'){
      this.fileASCSuperiore50MB= true;
      this.fileASCSizeInMB =  fileSizeInMB;
    }else if (layer==='DESC'){
      this.fileDESCSuperiore50MB= true;
      this.fileDESCSizeInMB =  fileSizeInMB;
    }else if (layer==='VERTICALE'){
      this.fileVERTICALESuperiore50MB = true;
      this.fileVERTICALESizeInMB =  fileSizeInMB;
    }
   
    let numberOfLines       = 0;
    let firstLineProcessed  = false;

    Papa.parse(file, {
      worker: true, // Usa un worker per evitare blocchi
      step: (row) => {
        numberOfLines++;
         if (!firstLineProcessed) {
          firstLineProcessed = true;
          const firstLine = row.data[0];
          const pattern = /^d\d{8}$/i;
          misurazioni = row.data.filter(item => pattern.test(item)).length;
          if (layer==='ASC'){
            this.separatoreASC = row.meta.delimiter === ',' ? 'virgola' : 'punto_virgola';
          }else if (layer==='DESC'){
            this.separatoreDESC = row.meta.delimiter === ',' ? 'virgola' : 'punto_virgola';
          }else if (layer==='VERTICALE'){
            this.separatoreVERTICALE = row.meta.delimiter === ',' ? 'virgola' : 'punto_virgola';
          }
         
        }
        },
        complete: () => {
        
          if (layer==='ASC'){
            this.iconaASC = "pi pi-check";
            this.misurazioniASCFile = $event.files[0];
            this.labelASC  = 'Il file ASC contiene ' + (numberOfLines-1) + ' record e '+ misurazioni + " misurazioni" ; //+ " ("+this.formatSize(this.misurazioniDESCFile.size) +")"
            this.classASC  = "btn-desc-upload-success";
            this.isCreatingProject= false;
            this.isFileUploaded = true;
            this.isLoadingFile= false;
          }else if (layer==='DESC'){
            this.iconaDESC = "pi pi-check";
            this.misurazioniDESCFile = $event.files[0];
            this.labelDESC  = 'Il file DESC contiene ' + (numberOfLines-1) + ' record e '+ misurazioni + " misurazioni" ; //+ " ("+this.formatSize(this.misurazioniDESCFile.size) +")"
            this.classDESC  = "btn-desc-upload-success";
            this.isCreatingProject= false;
            this.isFileUploaded = true;
            this.isLoadingFile= false;
            this.isFileDESCUploaded = true;

          }else if (layer==='VERTICALE'){
            this.misurazioniVERTICALEFile = $event.files[0];
            this.isFileVERTICALEUploaded = true;
            this.iconaVERTICALE = "pi pi-check";
            this.labelVERTICALE  = 'Il file VERTICALE contiene ' + (numberOfLines-1) + ' record e '+ misurazioni + " misurazioni" ;
            this.classVERTICALE  = "btn-desc-upload-success";
            this.isCreatingProject= false;
            this.isFileUploaded = true;
            this.isLoadingFile= false;
          }
         
          
        },
        error: (error) => {
          console.error("Errore durante il parsing del file CSV:", error);
        }
      });

  }else{
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e: any) => {
      const fileContent = e.target.result; // Contenuto del file CSV come stringa
      const lines = fileContent.split('\n'); // Divide il contenuto in righe
      const numberOfLines = lines.length;
      const firstLine = lines[0];
      const pattern = /[dD]\d{8}/g;  // Pattern che cerca "d" o "D" seguito da 8 cifre
      const matches = firstLine.match(pattern);  // Trova tutte le occorrenze
      misurazioni = matches ? matches.length : 0;

      const delimiters = [',', ';', '\t'];
      let detectedSeparator = ',';
      let maxOccurrences = 0;
      delimiters.forEach(delimiter => {
        const occurrences = firstLine.split(delimiter).length - 1;
        if (occurrences > maxOccurrences) {
          maxOccurrences = occurrences;
          detectedSeparator = delimiter;
        }
      });

      if (layer==='ASC'){
        this.separatoreASC = detectedSeparator===','?'virgola':'punto_virgola';
        this.iconaASC = "pi pi-check";
        this.misurazioniASCFile = file;
        this.labelASC  = 'Il file ASC contiene ' + (numberOfLines-1) + ' record e '+ misurazioni + " misurazioni" ; //+ " ("+this.formatSize(this.misurazioniDESCFile.size) +")"
        this.classASC  = "btn-desc-upload-success";
        this.isCreatingProject= false;
  
      }else if (layer==='DESC'){
        this.separatoreDESC = detectedSeparator===','?'virgola':'punto_virgola';
        this.isFileDESCUploaded = true;
        this.iconaDESC = "pi pi-check";
        this.misurazioniDESCFile = file;
        this.labelDESC  = 'Il file DESC contiene ' + (numberOfLines-1) + ' record e '+ misurazioni + " misurazioni" ; //+ " ("+this.formatSize(this.misurazioniDESCFile.size) +")"
        this.classDESC  = "btn-desc-upload-success";
        this.isCreatingProject= false;
  
      }else if (layer==='VERTICALE'){
        this.separatoreVERTICALE = detectedSeparator===','?'virgola':'punto_virgola';
        this.misurazioniVERTICALEFile = file;
        this.isFileVERTICALEUploaded = true;
        this.iconaVERTICALE = "pi pi-check";
        this.labelVERTICALE  = 'Il file VERTICALE contiene ' + (numberOfLines-1) + ' record e '+ misurazioni + " misurazioni" ;
        this.classVERTICALE  = "btn-desc-upload-success";
        this.isCreatingProject= false;
      } 
    }
  }
}

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {

      if(this.fileASCSuperiore50MB || this.fileDESCSuperiore50MB || this.fileVERTICALESuperiore50MB){
        this.confirmationService.confirm({
          target: event.target as EventTarget,
          message: 'Almeno uno dei file delle misurazioni ha una dimensione di oltre <strong>50 MB</strong>.<br> La sua elaborazione richiederà anche il tempo necessario al suo caricamento sul server.<br> <strong>Procedere comunque?</strong>',
          header: 'Attenzione',
          icon: 'pi pi-exclamation-triangle',
        /*   acceptIcon:"pi pi-check",
          rejectIcon:"pi pi-times", */
          rejectButtonStyleClass:"reject-fileupload-btn",
          acceptButtonStyleClass:"accept-fileupload-btn",
          acceptLabel:"SI'",
          rejectLabel:"NO",
          accept: () => {
            let progettoDaCreare: Progetto = new Progetto();
          progettoDaCreare.identificativo   = this.creaProgettoForm.value.identificativo;
          progettoDaCreare.descrizione      = this.creaProgettoForm.value.descrizione;
          progettoDaCreare.idAzienda        = this.creaProgettoForm.value.azienda.id;
          //progettoDaCreare.fileMisurazioni  = this.misurazioniFile;
          progettoDaCreare.separatoreCSVASC = this.separatoreASC;
          progettoDaCreare.separatoreCSVDESC = this.separatoreDESC;
          progettoDaCreare.separatoreCSVVERTICAL= this.separatoreVERTICALE;
          
          progettoDaCreare.fileMisurazioniASC         = this.misurazioniASCFile;
          progettoDaCreare.fileMisurazioniDESC        = this.misurazioniDESCFile;
          progettoDaCreare.fileMisurazioniVERTICALE   = this.misurazioniVERTICALEFile;
        
          progettoDaCreare.fileGeoPackage             = this.geoPackageFile;
          progettoDaCreare.fileQGis                   = this.qgisFile;
        
          this.isCreatingProject = true;
          this.progettiService.crea(progettoDaCreare).subscribe( {
              next: (resCreaProgetto) => {
                this.isCreatingProject = false;
                this.logger.error(resCreaProgetto);
                this.dialogRef.close({ success: true });
              },
              error: (e) => {
                  this.logger.error("ERRORE CREAZIONE PROGETTO" + e);
                  this.dialogRef.close({ success: false });
                  this.messageService.add({
                    severity: "error",
                    summary: "ATTENZIONE",
                    detail: "Errore in fase di creazione di un nuovo progetto!", life:10000
                  });
            },
              complete: () => {

                //this.isLoadingResults = false;
              }
          }); 
          },
          reject: () => {
            this.dialogRef.close({ success: false });
          }
        });
      }else{
        let progettoDaCreare: Progetto = new Progetto();
          progettoDaCreare.identificativo   = this.creaProgettoForm.value.identificativo;
          progettoDaCreare.descrizione      = this.creaProgettoForm.value.descrizione;
          progettoDaCreare.idAzienda        = this.creaProgettoForm.value.azienda.id;
          //progettoDaCreare.fileMisurazioni  = this.misurazioniFile;
          progettoDaCreare.separatoreCSVASC = this.separatoreASC;
          progettoDaCreare.separatoreCSVDESC = this.separatoreDESC;
          progettoDaCreare.separatoreCSVVERTICAL= this.separatoreVERTICALE;
          
          progettoDaCreare.fileMisurazioniASC         = this.misurazioniASCFile;
          progettoDaCreare.fileMisurazioniDESC        = this.misurazioniDESCFile;
          progettoDaCreare.fileMisurazioniVERTICALE   = this.misurazioniVERTICALEFile;
        
          progettoDaCreare.fileGeoPackage             = this.geoPackageFile;
          progettoDaCreare.fileQGis                   = this.qgisFile;
        
          this.isCreatingProject = true;
          this.progettiService.crea(progettoDaCreare).subscribe( {
              next: (resCreaProgetto) => {
                this.isCreatingProject = false;
                this.logger.error(resCreaProgetto);
                this.dialogRef.close({ success: true });
              },
              error: (e) => {
                  this.logger.error("ERRORE CREAZIONE PROGETTO" + e);
                  this.dialogRef.close({ success: false });
                  this.messageService.add({
                    severity: "error",
                    summary: "ATTENZIONE",
                    detail: "Errore in fase di creazione di un nuovo progetto!", life:10000
                  });
            },
              complete: () => {

                //this.isLoadingResults = false;
              }
          }); 
      }

      
      
    }
  }
}
