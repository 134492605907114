

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { UtentiService } from "src/app/services/utenti.service";
import { Azienda } from "../../../models/fragile/azienda.model";
import { AziendeService } from "src/app/services/aziende.service";
import { UtilService } from "src/app/services/util.service";


@Component({
  templateUrl: "./dialog-crea-azienda.component.html",
  styleUrls: ["../aziende.component.css"],
})
export class CreaAziendaComponent implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected aziende: Azienda[]= [];
  public creaAziendaForm: FormGroup;


  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private aziendeService: AziendeService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,

  ) {

  }

  ngOnInit(): void {
    this.creaAziendaForm = this._formBuilder.group({
      ragioneSociale:   [null, [Validators.required]],
      colore:           [null, [Validators.required]]
    });
  
  }
  
 

  

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let aziendaDaCreare: Azienda = new Azienda();
      aziendaDaCreare.ragioneSociale = this.creaAziendaForm.value.ragioneSociale;
      aziendaDaCreare.colore = this.creaAziendaForm.value.colore;
     
      this.aziendeService.crea(aziendaDaCreare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          console.error("ERRORE CREAZIONE AZIENDA" + error);
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di creazione azienda!", life:10000
          });
        }
      );
    }
  }
}
