import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";

import {DOCUMENT} from "@angular/common";
import {NGXLogger} from "ngx-logger";
import {TabViewChangeEvent} from "primeng/tabview";
import {environment} from "../../../environments/environment";
import {AngularFirestore, AngularFirestoreCollection} from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { Timestamp, onSnapshot } from "firebase/firestore";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmationService, MenuItem, MessageService, PrimeIcons } from "primeng/api";
import { Misurazione_Firestore } from "src/app/models/firestore/misurazioneFirestore.model";
import { ChartModule } from 'primeng/chart';
import {AuthService} from "@auth0/auth0-angular";
import { HttpClient } from '@angular/common/http';
import { UserService } from "src/app/services/user.service";
import { Progetto_Firestore } from "src/app/models/firestore/progettoFirestore.model";
import { UtilService } from "src/app/services/util.service";
import { STILI_STATO_CARICAMENTO, STILI_STATO_PROGETTO } from "src/app/shared/stili";
import { Azienda } from "src/app/models/fragile/azienda.model";
import { AziendeService } from "src/app/services/aziende.service";
import { CreaProgettoComponent } from "./crea-progetto/crea-progetto";
import { Menu } from "primeng/menu";
import { ProgettiService } from "src/app/services/progetti.service";
import { StoricoCaricamentiComponent } from "./storico-caricamenti/storico-caricamenti.component";
import { GoogleStorageService } from "src/app/services/googleStorage.service";
import { getStorage, ref, getBlob } from "firebase/storage";
import { saveAs } from "file-saver";
import { CaricaFileQGIComponent } from "./carica-file/carica-qgis";
import { CaricaFileGEOPackageComponent } from "./carica-file/carica-geoPackage";
import { CaricaFileCSVComponent } from "./carica-file/carica-csv";
import { ModificaProgettoComponent } from "./modifica-progetto/modifica-progetto";
import JSZip from 'jszip';
import { forkJoin } from "rxjs";

@Component({
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  providers: [DialogService, ConfirmationService, MessageService],
})
export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChild("progettiDT", { static: true }) progettiDT: any;

  @ViewChild("menuAzioniTiered", { static: true }) azioniMenuTiered: Menu;

  protected isLoadingResults: boolean = true;
  protected showDeleteConfirmation: boolean = false;
  protected showCaricaMisurazioniConfirmation: boolean = false;
  protected showEliminaMisurazioniConfirmation: boolean = false;
  public isReady: boolean = false;
 
  public misurazioni_punto:Misurazione_Firestore;
  private progetti: Progetto_Firestore[] = [];
  public selectedProgetto:Progetto_Firestore;
  public selectedLayer:string;
  protected aziende:Azienda[]=[];

  ref: DynamicDialogRef;
  options: any;
  data: any;

  private collection_progetti:string = environment.firebase.collectionProgetti;
  protected stiliStatoCaricamento = STILI_STATO_CARICAMENTO;
  protected stiliStatoProgetto = STILI_STATO_PROGETTO;

  protected menuAzioniItems!: MenuItem[];


  constructor(
    public auth: AuthService,
    private logger: NGXLogger,
    private firestore: AngularFirestore,
    private router: Router,
    public dialogService: DialogService,
    private messageService:MessageService,
    @Inject(DOCUMENT) private doc: Document,
    private http: HttpClient,
    private userService: UserService,
    public utilService:UtilService,
    private aziendeService:AziendeService,
    private progettiService:ProgettiService,
    private googleStorageService:GoogleStorageService,
    private confirmationService: ConfirmationService,

  ) {
    this.isLoadingResults= true;
    this.loadAziende();
    
  }

  ngOnInit() {
    this.logger.info("ONINIT @ HOME");

    this.auth.user$.subscribe((user) => {
      if (user != null) {
        //this.logger.info(user);

        this.userService.loadSetUserInformation(user).then(() => {
          this.loadProgetti();

        });
      
      }});   
   
  
  }


  ngAfterViewInit() {
  }

  async openCaricaAzioni(  event: any, progetto:Progetto_Firestore): Promise<void> {
    this.selectedProgetto =  progetto ;
    this.loadAzioni(event, progetto );
    this.azioniMenuTiered.toggle(event);
  }
  loadAzioni(event:any, progetto:Progetto_Firestore) {
    this.menuAzioniItems = [];
    this.menuAzioniItems.push({
        label: "Visualizza",
        icon: PrimeIcons.CHEVRON_CIRCLE_RIGHT,
        style: { 
          'font-size': '13px',
          'font-weight': 'bold'
        },
        command: () => this.openViewer(progetto),
      }
    );
    /*  this.menuAzioniItems.push(
      {
       label: "Modifica",
        icon: PrimeIcons.PENCIL,
        style: { 
          'font-size': '13px',
          'font-weight': 'bold',
          command: () => this.modificaProgetto(progetto),
        }, 
       
      });*/
    if(this.userService.isAdmin){
      this.menuAzioniItems.push(
        {
          label: "Progetto QGIS",
          icon: PrimeIcons.BOOK,
          style: { 
            'font-size': '13px',
            'font-weight': 'bold'
          },
          items: [
            {
              label: "Scarica Pacchetto",
              icon: PrimeIcons.DOWNLOAD,
              style: { 'font-size': '12.5px'},
              command: () => this.scaricaFile(progetto),
            },
            {
              label: "Ricarica File QGS",
              icon: PrimeIcons.MAP_MARKER,
              style: { 'font-size': '12px'},
              command: () => this.ricaricaFile(progetto, true),
            },
            {
              label: "Ricarica GeoPackage",
              icon: PrimeIcons.MAP,
              style: { 'font-size': '11.5px'},
              command: () => this.ricaricaFile(progetto, false),
            },
          ]
        });
      
        let itemsMisurazioni = [];
        
        
        if(progetto.layers.indexOf('ASC')!=-1){
          let itemsMisurazioniASC = [];
          if(!progetto.statoCaricamentoASC){
            itemsMisurazioniASC.push({
              label: "Carica",
              icon: PrimeIcons.CLOUD_UPLOAD,
              style: { 'font-size': '13px'},
              command: () => this.caricaMisurazioni(progetto, false, 'ASC'),
            });
          }else if( progetto.statoCaricamentoASC==='ELIMINATO'){
            itemsMisurazioniASC.push({
              label: "Storico",
              icon: PrimeIcons.HISTORY,
              style: { 'font-size': '13px'},
              command: () => this.apriStoricoMisurazioni(progetto, false, 'ASC'),
            },{
              label: "Carica",
              icon: PrimeIcons.CLOUD_UPLOAD,
              style: { 'font-size': '13px'},
              command: () => this.caricaMisurazioni(progetto, true, 'ASC'),
            }
          );
          }else if( progetto.statoCaricamentoASC==='IN_CORSO'){
           
          }else if(progetto.statoCaricamentoASC==='COMPLETATO'){
            let itemsAggiungi =[];
            itemsAggiungi.push({
              label: "Punti",
              icon: PrimeIcons.CHART_SCATTER,
              style: { 'font-size': '13px'},
              command: () => this.caricaPunti(progetto,  'ASC'),
            },{
              label: "Misurazioni",
              icon: PrimeIcons.CALENDAR_PLUS,
              style: { 'font-size': '13px'},
              command: () => this.caricaMisurazioni(progetto, true, 'ASC'),
            }
          );
            itemsMisurazioniASC.push({
              label: "Storico",
              icon: PrimeIcons.HISTORY,
              style: { 'font-size': '13px'},
              command: () => this.apriStoricoMisurazioni(progetto, false, 'ASC'),
            },{
              label: "Carica",
              icon: PrimeIcons.CLOUD_UPLOAD,
              style: { 'font-size': '13px'},
              command: () => this.caricaMisurazioni(progetto, false, 'ASC'),
            }
          );
          itemsMisurazioniASC.push( {
              label: "Aggiungi",
              icon: PrimeIcons.PLUS_CIRCLE,
              style: { 'font-size': '13px'},
              items:itemsAggiungi
              //command: () => this.caricaMisurazioni(progetto, true, 'ASC'),
            });
            /* itemsMisurazioniASC.push( {
              label: "Elimina",
              icon: PrimeIcons.ERASER,
              style: { 'font-size': '13px'},
              command: () => this.eliminaMisurazioni(progetto, 'ASC'),
            }); */
          } 
          itemsMisurazioni.push({
            label: "ASC",
            icon: PrimeIcons.ARROW_UP,
            style: { 'font-size': '13px'},
            items: itemsMisurazioniASC
          });
        }
        if(progetto.layers.indexOf('DESC')!=-1){
          let itemsMisurazioniDESC = [];
          if(!progetto.statoCaricamentoDESC){
            itemsMisurazioniDESC.push({
              label: "Carica",
              icon: PrimeIcons.CLOUD_UPLOAD,
              style: { 'font-size': '13px'},
              command: () => this.caricaMisurazioni(progetto, false, 'DESC'),
            });
          }else if( progetto.statoCaricamentoDESC==='ELIMINATO'){
            itemsMisurazioniDESC.push({
              label: "Storico",
              icon: PrimeIcons.HISTORY,
              style: { 'font-size': '13px'},
              command: () => this.apriStoricoMisurazioni(progetto, false, 'DESC'),
            },{
              label: "Carica",
              icon: PrimeIcons.CLOUD_UPLOAD,
              style: { 'font-size': '13px'},
              command: () => this.caricaMisurazioni(progetto, true, 'DESC'),
            }
          );
          }else if( progetto.statoCaricamentoDESC==='IN_CORSO'){
           
          }else if(progetto.statoCaricamentoDESC==='COMPLETATO'){
            let itemsAggiungi =[];
            itemsAggiungi.push({
              label: "Punti",
              icon: PrimeIcons.CHART_SCATTER,
              style: { 'font-size': '13px'},
              command: () => this.caricaPunti(progetto, 'DESC'),
            },{
              label: "Misurazioni",
              icon: PrimeIcons.CALENDAR_PLUS,
              style: { 'font-size': '13px'},
              command: () => this.caricaMisurazioni(progetto, true, 'DESC'),
            }
          );
            itemsMisurazioniDESC.push({
              label: "Storico",
              icon: PrimeIcons.HISTORY,
              style: { 'font-size': '13px'},
              command: () => this.apriStoricoMisurazioni(progetto, false, 'DESC'),
            },{
              label: "Carica",
              icon: PrimeIcons.CLOUD_UPLOAD,
              style: { 'font-size': '13px'},
              command: () => this.caricaMisurazioni(progetto, false, 'DESC'),
            }
          );
          itemsMisurazioniDESC.push( {
              label: "Aggiungi",
              icon: PrimeIcons.PLUS_CIRCLE,
              style: { 'font-size': '13px'},
             // command: () => this.caricaMisurazioni(progetto, true, 'DESC'),
             items:itemsAggiungi
            });
           /*  itemsMisurazioniDESC.push( {
              label: "Elimina",
              icon: PrimeIcons.ERASER,
              style: { 'font-size': '13px'},
              command: () => this.eliminaMisurazioni(progetto, 'DESC'),
            }); */
          } 
          itemsMisurazioni.push({
            label: "DESC",
            icon: PrimeIcons.ARROW_DOWN,
            style: { 'font-size': '13px'},
            items: itemsMisurazioniDESC
          });
        }
        if(progetto.layers.indexOf('VERTICALE')!=-1){
          let itemsMisurazioniVERTICALE = [];
          if(!progetto.statoCaricamentoVERTICALE){
            itemsMisurazioniVERTICALE.push({
              label: "Carica",
              icon: PrimeIcons.CLOUD_UPLOAD,
              style: { 'font-size': '13px'},
              command: () => this.caricaMisurazioni(progetto, false, 'VERTICALE'),
            });
          }else if( progetto.statoCaricamentoVERTICALE==='ELIMINATO'){
            itemsMisurazioniVERTICALE.push({
              label: "Storico",
              icon: PrimeIcons.HISTORY,
              style: { 'font-size': '13px'},
              command: () => this.apriStoricoMisurazioni(progetto, false, 'VERTICALE'),
            },{
              label: "Carica",
              icon: PrimeIcons.CLOUD_UPLOAD,
              style: { 'font-size': '13px'},
              command: () => this.caricaMisurazioni(progetto, true, 'VERTICALE'),
            }
          );
          }else if( progetto.statoCaricamentoVERTICALE==='IN_CORSO'){
           
          }else if(progetto.statoCaricamentoVERTICALE==='COMPLETATO'){
            itemsMisurazioniVERTICALE.push({
              label: "Storico",
              icon: PrimeIcons.HISTORY,
              style: { 'font-size': '13px'},
              command: () => this.apriStoricoMisurazioni(progetto, false, 'VERTICALE'),
            },{
              label: "Carica",
              icon: PrimeIcons.CLOUD_UPLOAD,
              style: { 'font-size': '13px'},
              command: () => this.caricaMisurazioni(progetto, false, 'VERTICALE'),
            }
          );
          itemsMisurazioniVERTICALE.push( {
              label: "Aggiungi",
              icon: PrimeIcons.PLUS_CIRCLE,
              style: { 'font-size': '13px'},
              command: () => this.caricaMisurazioni(progetto, true, 'VERTICALE'),
            });
            /* itemsMisurazioniVERTICALE.push( {
              label: "Elimina",
              icon: PrimeIcons.ERASER,
              style: { 'font-size': '13px'},
              command: () => this.eliminaMisurazioni(progetto, 'VERTICALE'),
            }); */
          } 
          itemsMisurazioni.push({
            label: "VERTICALE",
            icon: PrimeIcons.ARROW_V,
            style: { 'font-size': '13px'},
            items: itemsMisurazioniVERTICALE
          });
        }
        if(progetto.layers==null || progetto.layers.length == 0){
          let itemsAggiungiLayer = [];
         
          itemsAggiungiLayer.push(
            {
            label: "Asc",
            icon: PrimeIcons.ARROW_UP,
            style: { 'font-size': '13px'},
            command: () => this.aggiungiLayer(progetto, 'ASC'),
           },
           {
            label: "Desc",
            icon: PrimeIcons.ARROW_DOWN,
            style: { 'font-size': '12px'},
            command: () => this.aggiungiLayer(progetto, 'DESC'),
           },
           {
            label: "Verticale",
            icon: PrimeIcons.ARROW_V,
            style: { 'font-size': '12px'},
            command: () => this.aggiungiLayer(progetto, 'VERTICALE'),
           },
          );
        itemsMisurazioni.push(
          {
            label: "Aggiungi Layer",
            icon: PrimeIcons.PLUS,
            style: { 'font-size': '12px'},
            items:itemsAggiungiLayer
          }
        );
      }else{
        let itemsAggiungiLayer = [];
         
        if(progetto.layers.indexOf('ASC')==-1){
          itemsAggiungiLayer.push(
            {
            label: "Asc",
            icon: PrimeIcons.ARROW_UP,
            style: { 'font-size': '12px'},
            command: () => this.aggiungiLayer(progetto, 'ASC'),
           });
        }
        if(progetto.layers.indexOf('DESC')==-1){
          itemsAggiungiLayer.push(
           {
            label: "Desc",
            icon: PrimeIcons.ARROW_DOWN,
            style: { 'font-size': '12px'},
            command: () => this.aggiungiLayer(progetto, 'DESC'),
           }
          );
        }
        if(progetto.layers.indexOf('VERTICALE')==-1){
          itemsAggiungiLayer.push(
           {
              label: "Verticale",
              icon: PrimeIcons.ARROW_V,
              style: { 'font-size': '12px'},
              command: () => this.aggiungiLayer(progetto, 'VERTICALE'),
             },
           
          );
        }
        if(itemsAggiungiLayer.length !=0){
          itemsMisurazioni.push(
            {
              label: "Aggiungi Layer",
              icon: PrimeIcons.PLUS,
              style: { 'font-size': '12px'},
              items:itemsAggiungiLayer
            }
          );
        } 
      }
       /* 
        if(!progetto.statoCaricamento){
          itemsMisurazioni.push({
            label: "Carica",
            icon: PrimeIcons.CLOUD_UPLOAD,
            style: { 'font-size': '13px'},
            command: () => this.caricaMisurazioni(progetto, false),
          });
        }else if( progetto.statoCaricamento==='ELIMINATO'){
          itemsMisurazioni.push({
            label: "Storico",
            icon: PrimeIcons.HISTORY,
            style: { 'font-size': '13px'},
            command: () => this.apriStoricoMisurazioni(progetto, false),
          },{
            label: "Carica",
            icon: PrimeIcons.CLOUD_UPLOAD,
            style: { 'font-size': '13px'},
            command: () => this.caricaMisurazioni(progetto, true),
          }
        );
        }else if( progetto.statoCaricamento==='IN_CORSO'){
         
        }else if(progetto.statoCaricamento==='COMPLETATO'){
          itemsMisurazioni.push({
            label: "Storico",
            icon: PrimeIcons.HISTORY,
            style: { 'font-size': '13px'},
            command: () => this.apriStoricoMisurazioni(progetto, false),
          },{
            label: "Carica",
            icon: PrimeIcons.CLOUD_UPLOAD,
            style: { 'font-size': '13px'},
            command: () => this.caricaMisurazioni(progetto, false),
          }
        );
          itemsMisurazioni.push( {
            label: "Aggiungi",
            icon: PrimeIcons.PLUS_CIRCLE,
            style: { 'font-size': '13px'},
            command: () => this.caricaMisurazioni(progetto, true),
          });
          itemsMisurazioni.push( {
            label: "Elimina",
            icon: PrimeIcons.ERASER,
            style: { 'font-size': '13px'},
            command: () => this.eliminaMisurazioni(progetto),
          });
        } */
        this.menuAzioniItems.push(
        {
        label: "Misurazioni",
        icon: PrimeIcons.LIST,
        style: { 
          'font-size': '13px',
          'font-weight': 'bold'
        },
        items: itemsMisurazioni/*  [
          {
            label: "Storico",
            icon: PrimeIcons.HISTORY,
            style: { 'font-size': '13px'},
            command: () => this.apriStoricoMisurazioni(progetto, false),
          },{
            label: "Carica",
            icon: PrimeIcons.CLOUD_UPLOAD,
            style: { 'font-size': '13px'},
            command: () => this.caricaMisurazioni(progetto, false),
          },
          {
            label: "Aggiorna",
            icon: PrimeIcons.HISTORY,
            style: { 'font-size': '13px'},
            command: () => this.caricaMisurazioni(progetto, true),
          },
        ] */
      },
      /* {
        label: "Elimina",
        icon: PrimeIcons.TRASH,
        style: { 
          'font-size': '13px',
          'font-weight': 'bold'
        },
        command: () => this.eliminaProgetto(progetto),
      } */
    );
    }else if(this.userService.isUtente){
      this.menuAzioniItems.push();
    }
  }

  getIntervalloDatiASC(progetto:Progetto_Firestore):string{
    if(progetto.dataInizioIntervalloCaricamentiASC){
      return this.utilService.formattaDataMisurazione(progetto.dataInizioIntervalloCaricamentiASC) + ':' +this.utilService.formattaDataMisurazione(progetto.dataFineIntervalloCaricamentiASC)
    }
    return '';
  }

  getIntervalloDatiDESC(progetto:Progetto_Firestore):string{
    if(progetto.dataInizioIntervalloCaricamentiDESC){
      return this.utilService.formattaDataMisurazione(progetto.dataInizioIntervalloCaricamentiDESC) + ':' +this.utilService.formattaDataMisurazione(progetto.dataFineIntervalloCaricamentiDESC)
    }
    return '';
  }
  getIntervalloDatiVERTICALE(progetto:Progetto_Firestore):string{
    if(progetto.dataInizioIntervalloCaricamentiVERTICALE){
      return this.utilService.formattaDataMisurazione(progetto.dataInizioIntervalloCaricamentiVERTICALE) + ':' +this.utilService.formattaDataMisurazione(progetto.dataFineIntervalloCaricamentiVERTICALE)
    }
    return '';
  }

  getDataUltimoCaricamentoASC(progetto:Progetto_Firestore):string{
    return progetto.dataUltimoCaricamentoASC?this.utilService.formattaData(progetto.dataUltimoCaricamentoASC):''
  }
  getDurataUltimoCaricamentoASC(progetto:Progetto_Firestore):string{
    if(progetto.durataUltimoCaricamentoASC){
      if(progetto.durataUltimoCaricamentoASC < 60){
        return 'Durata '+progetto.durataUltimoCaricamentoASC+ ' secondi';
      }else{
        return 'Durata '+Math.floor(progetto.durataUltimoCaricamentoASC/60)+ ' minuti';
      }
    }
    return '';
  }
  getDurataUltimoCaricamentoDESC(progetto:Progetto_Firestore):string{
    if(progetto.durataUltimoCaricamentoDESC){
      if(progetto.durataUltimoCaricamentoDESC < 60){
        return 'Durata '+progetto.durataUltimoCaricamentoDESC+ ' secondi';
      }else{
        return 'Durata '+Math.floor(progetto.durataUltimoCaricamentoDESC/60)+ ' minuti';
      }
    }
    return '';
  }
  getDataUltimoCaricamentoDESC(progetto:Progetto_Firestore):string{
    return progetto.dataUltimoCaricamentoDESC?this.utilService.formattaData(progetto.dataUltimoCaricamentoDESC):''
  }
  getDataUltimoCaricamentoVERTICALE(progetto:Progetto_Firestore):string{
    return progetto.dataUltimoCaricamentoVERTICALE?this.utilService.formattaData(progetto.dataUltimoCaricamentoVERTICALE):''
  }
  getDurataUltimoCaricamentoVERTICALE(progetto:Progetto_Firestore):string{
    if(progetto.durataUltimoCaricamentoVERTICALE){
      if(progetto.durataUltimoCaricamentoVERTICALE < 60){
        return 'Durata '+progetto.durataUltimoCaricamentoVERTICALE+ ' secondi';
      }else{
        return 'Durata '+Math.floor(progetto.durataUltimoCaricamentoVERTICALE/60)+ ' minuti';
      }
    }
    return '';
  }

  getLayerColor(layer: string): string {
    switch (layer){
      case 'ASC':
        return '#5398d5';
      case 'DESC':
        return '#054357';
      case 'VERTICALE':
          return '#59bd9e';
      default:
        return '#5398d5';
    }
  }
  getStatoCaricamentoLayerColor(stato:string, layer: string): string {
    switch (layer){
      case 'ASC':
        if(stato==='IN CORSO'){
          return '#E2C565';
        }
        return '#5398d5';
      case 'DESC':
        if(stato==='IN CORSO'){
          return '#E2C565';
        }
        return '#054357';
      case 'VERTICALE':
        if(stato==='IN CORSO'){
          return '#E2C565';
        }
        return '#59bd9e';
      default:
        if(stato==='IN CORSO'){
          return '#E2C565';
        }
        return '#5398d5';
    }
  }
  getLayerIcon(layer: string): string {
    switch (layer){
      case 'ASC':
        return 'pi pi-arrow-up';
      case 'DESC':
        return 'pi pi-arrow-down';
      case 'VERTICALE':
        return 'pi pi-arrows-v';
      default:
        return 'pi pi-arrow-up';
    }
  }

  openViewer(progetto:Progetto_Firestore){
    const url = environment.viewerUrl+progetto.identificativo+"#/map";
    window.open(url, '_blank');
    
  }
  modificaProgetto(progetto:Progetto_Firestore){
    this.ref = this.dialogService.open(ModificaProgettoComponent, {
      data: {
        progetto: progetto,
      },
      header: "Modifica Progetto "+progetto.identificativo.toUpperCase(),
      width:  "70%" ,
      height: "50%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirmCaricamentoQGISFile) => {
       console.log("AFTER CLOSED > "+ confirmCaricamentoQGISFile.success);
      if (confirmCaricamentoQGISFile && confirmCaricamentoQGISFile.success) {
        this.messageService.add({severity:'success', summary:'', detail:'Progetto modificato con successo!', life:3000});
        this.loadProgetti();
      }
    });
  }

  async scaricaFile(progetto:Progetto_Firestore){
    const zip = new JSZip();

    const urlQGSFile        = 'https://viewer2-test.maphosting.it/viewer/v_viewer_util.php?dachi=MIOCONF&action=getFileExt&configname='+progetto.identificativo+'&cliname=fragile&ext=qgs';
    const urlGEOPackageFile = 'https://viewer2-test.maphosting.it/viewer/v_viewer_util.php?dachi=MIOCONF&action=getFileExt&configname='+progetto.identificativo+'&cliname=fragile&ext=gpkg';
    this.isLoadingResults = true;
    // Scarica entrambi i file
    const QGSFile$ = this.http.get(urlQGSFile, { responseType: 'blob' });
    const GEOPackageFile$ = this.http.get(urlGEOPackageFile, { responseType: 'blob' });
     forkJoin([QGSFile$, GEOPackageFile$]).subscribe(([QGSFile, GEOPackageFile]) => {
     this.logger.info(QGSFile);
     this.logger.info(GEOPackageFile);
      // Aggiungi i file allo zip
      zip.file(progetto.identificativo+'.qgs', QGSFile);
      zip.file(progetto.identificativo+'.gpkg', GEOPackageFile);

      // Genera lo zip
      zip.generateAsync({ type: 'blob' }).then((zipFile) => {
        saveAs(zipFile, progetto.identificativo+'.zip');
        this.isLoadingResults = false;
      });
    }, error => {
      this.isLoadingResults = false;
      console.error('Errore durante il download dei file del pacchetto:', error);
      this.messageService.add({life: 10000,severity:'error', summary:'Errore', detail:'Errore in fase di scaricamento dei file QGIS del progetto '+progetto.identificativo});

    });
  }

  ricaricaFile(progetto:Progetto_Firestore, isQGIS:boolean){
    if(isQGIS){
      this.ref = this.dialogService.open(CaricaFileQGIComponent, {
        data: {
          progetto: progetto,
        },
        header: "Carica file QGIS del Progetto "+progetto.identificativo.toUpperCase(),
        width:  "50%" ,
        height: "35%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((confirmCaricamentoQGISFile) => {
         console.log("AFTER CLOSED > "+ confirmCaricamentoQGISFile.success);
        if (confirmCaricamentoQGISFile && confirmCaricamentoQGISFile.success) {
          this.messageService.add({severity:'success', summary:'', detail:'File caricato con successo!', life:3000});
          this.loadProgetti();
        }
      });
    }else{
      this.ref = this.dialogService.open(CaricaFileGEOPackageComponent, {
        data: {
          progetto: progetto,
        },
        header: "Carica file GEOPACKAGE del Progetto "+progetto.identificativo.toUpperCase(),
        width:  "50%" ,
        height: "35%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((confirmCaricamentoQGISFile) => {
         console.log("AFTER CLOSED > "+ confirmCaricamentoQGISFile.success);
        if (confirmCaricamentoQGISFile && confirmCaricamentoQGISFile.success) {
          this.messageService.add({severity:'success', summary:'', detail:'File caricato con successo!', life:3000});
          this.loadProgetti();
        }
      });
    }

  }

  eliminaMisurazioni(progetto:Progetto_Firestore, layer:string){
    this.showEliminaMisurazioniConfirmation = true;
    this.selectedProgetto = progetto;
    this.selectedLayer = layer;

  }
  confermaEliminaMisurazioniProgetto(progetto:Progetto_Firestore){
    this.showEliminaMisurazioniConfirmation = false;
    this.isLoadingResults = true;
    this.progettiService.eliminaMisurazioni(this.selectedProgetto.id, this.selectedLayer).subscribe( {
        next: (v) => {

        },
        error: (e) => {
            this.messageService.add({life: 10000,severity:'error', summary:'Errore', detail:'Errore in fase di eliminazione delle misurazioni del progetto '+this.selectedProgetto.identificativo});
            this.isLoadingResults = false;
        },
        complete: () => {
            this.messageService.add({severity:'success', summary:'', detail:'Misurazioni del progetto '+ this.selectedProgetto.identificativo+ ' eliminate!', life:5000});
            this.loadProgetti();
            this.isLoadingResults = false;
            
        }
    });
  }
  aggiungiLayer(progetto:Progetto_Firestore, layer:string){
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(environment.firebase.collectionProgetti).ref.where('id', "==", progetto.id);
    collectionRef.get().then((querySnapshot) => {
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const data = doc.data() as Progetto_Firestore;
  
        // Verifica se il campo `layers` esiste e se è un array
        const layers = Array.isArray(data.layers) ? data.layers : [];
  
        // Aggiungi il nuovo layer solo se non esiste già nell'array
        if (!layers.includes(layer)) {
          layers.push(layer);
  
          // Aggiorna il campo `layers` nel documento
          doc.ref.update({ layers })
            .then(() => {
              console.log(`Layer '${layer}' aggiunto al progetto ${progetto.id}.`);
            })
            .catch((error) => {
              console.error(`Errore nell'aggiornamento del layer:`, error);
            });
        } else {
          console.log(`Layer '${layer}' già presente nel progetto ${progetto.id}.`);
        }
      } else {
        console.log(`Progetto con id ${progetto.id} non trovato.`);
      }
      this.isLoadingResults = true;
    }).catch((error) => {
      console.error(`Errore nella ricerca del progetto:`, error);
      this.isLoadingResults = true;
    });

  }

  caricaPunti(progetto:Progetto_Firestore, layer:string){
    if(layer==='ASC'){
      this.ref = this.dialogService.open(CaricaFileCSVComponent, {
        data: {
          progetto: progetto,
          isOverwrite:false,
          isPunti:true,
          layer:layer
        },
        header: "Aggiungi PUNTI al layer ASC del progetto "+progetto.identificativo.toUpperCase(),
        width:  "60%" ,
        height: "35%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((confirmNewProgetto) => {
        if (confirmNewProgetto && confirmNewProgetto.success) {
          this.messageService.add({severity:'success', summary:'', detail:'Punti del layer ASC aggiunti con successo!', life:3000});
          this.loadProgetti();
        }
      })
    }else if(layer==='DESC'){
      this.ref = this.dialogService.open(CaricaFileCSVComponent, {
        data: {
          progetto: progetto,
          isOverwrite:false,
          isPunti:true,
          layer:layer
        },
        header: "Aggiungi PUNTI al layer DESC del progetto "+progetto.identificativo.toUpperCase(),
        width:  "60%" ,
        height: "35%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((confirmNewProgetto) => {
         console.log("AFTER CLOSED > "+ confirmNewProgetto.success);
        if (confirmNewProgetto && confirmNewProgetto.success) {
          this.messageService.add({severity:'success', summary:'', detail:'Punti del layer DESC aggiunti con successo!', life:3000});
          this.loadProgetti();
        }
      });
    }
  }
  caricaMisurazioni(progetto:Progetto_Firestore, isUpdate:boolean, layer:string){
    if(layer==='ASC'){
      
      if(isUpdate || (!isUpdate && !progetto.statoCaricamentoASC)){
        this.ref = this.dialogService.open(CaricaFileCSVComponent, {
          data: {
            progetto: progetto,
            isOverwrite:false,
            isPunti:false,
            layer:layer
          },
          header: "Aggiungi MISURAZIONI al layer ASC del progetto "+progetto.identificativo.toUpperCase(),
          width:  "60%" ,
          height: "35%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
        this.ref.onClose.subscribe((confirmNewProgetto) => {
           console.log("AFTER CLOSED > "+ confirmNewProgetto.success);
          if (confirmNewProgetto && confirmNewProgetto.success) {
            this.messageService.add({severity:'success', summary:'', detail:'Misurazioni del layer ASC progetto caricate con successo!', life:3000});
            this.loadProgetti();
          }
        });
      }else{
        this.selectedLayer = 'ASC';
        this.showCaricaMisurazioniConfirmation = true;
        this.selectedProgetto = progetto;
      }
    }else if(layer==='DESC'){
      if(isUpdate || (!isUpdate && !progetto.statoCaricamentoASC)){
        this.ref = this.dialogService.open(CaricaFileCSVComponent, {
          data: {
            progetto: progetto,
            isOverwrite:false,
            isPunti:false,
            layer:layer
          },
          header: "Aggiungi MISURAZIONI al layer DESC del progetto "+progetto.identificativo.toUpperCase(),
          width:  "60%" ,
          height: "35%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
        this.ref.onClose.subscribe((confirmNewProgetto) => {
           console.log("AFTER CLOSED > "+ confirmNewProgetto.success);
          if (confirmNewProgetto && confirmNewProgetto.success) {
            this.messageService.add({severity:'success', summary:'', detail:'Misurazioni del layer DESC progetto caricate con successo!', life:3000});
            this.loadProgetti();
          }
        });
      }else{
        this.selectedLayer = 'DESC';
        this.showCaricaMisurazioniConfirmation = true;
        this.selectedProgetto = progetto;
      }
    }else if(layer==='VERTICALE'){
      if(isUpdate || (!isUpdate && !progetto.statoCaricamentoVERTICALE)){
        this.ref = this.dialogService.open(CaricaFileCSVComponent, {
          data: {
            progetto: progetto,
            isOverwrite:false,
            isPunti:false,
            layer:layer
          },
          header: "Aggiungi MISURAZIONI al layer VERTICALE del progetto "+progetto.identificativo.toUpperCase(),
          width:  "60%" ,
          height: "35%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
        this.ref.onClose.subscribe((confirmNewProgetto) => {
           console.log("AFTER CLOSED > "+ confirmNewProgetto.success);
          if (confirmNewProgetto && confirmNewProgetto.success) {
            this.messageService.add({severity:'success', summary:'', detail:'Misurazioni del layer VERTICALE progetto caricate con successo!', life:3000});
            this.loadProgetti();
          }
        });
      }else{
        this.selectedLayer = 'VERTICALE';
        this.showCaricaMisurazioniConfirmation = true;
        this.selectedProgetto = progetto;
      }
    }
    
    
    
    /* if(isUpdate || (!isUpdate && !progetto.statoCaricamento)){
      this.ref = this.dialogService.open(CaricaFileCSVComponent, {
        data: {
          progetto: progetto,
          isOverwrite:false
        },
        header: "Aggiungi misurazioni al progetto "+progetto.identificativo.toUpperCase(),
        width:  "40%" ,
        height: "35%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((confirmNewProgetto) => {
         console.log("AFTER CLOSED > "+ confirmNewProgetto.success);
        if (confirmNewProgetto && confirmNewProgetto.success) {
          this.messageService.add({severity:'success', summary:'', detail:'Misurazioni del progetto caricate con successo!', life:3000});
          this.loadProgetti();
        }
      });
    }else{
      this.showCaricaMisurazioniConfirmation = true;
      this.selectedProgetto = progetto;
      
    } */
  }

  confermaCaricaMisurazioniProgetto(progetto:Progetto_Firestore){
    this.showCaricaMisurazioniConfirmation = false;
    this.ref = this.dialogService.open(CaricaFileCSVComponent, {
      data: {
        progetto: this.selectedProgetto,
        isOverwrite:true,
        layer:this.selectedLayer
      },
      header: "Carica MISURAZIONI su layer " + this.selectedLayer.toUpperCase()+ "del progetto "+this.selectedProgetto.identificativo.toUpperCase(),
      width:  "40%" ,
      height: "35%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirmNewProgetto) => {
       console.log("AFTER CLOSED > "+ confirmNewProgetto.success);
      if (confirmNewProgetto && confirmNewProgetto.success) {
        this.messageService.add({severity:'success', summary:'', detail:'Misurazioni del progetto caricate con successo!', life:3000});
        this.loadProgetti();
      }
    });
  }

  async scaricaTemplate(){
    const storage = getStorage();
    const pathReferenceTemplateCaricamenti  = ref( storage, "templates/template_caricamento.csv");

    const blob = await getBlob(pathReferenceTemplateCaricamenti);
    saveAs(blob, "template_caricamenti.csv");
  }
  apriStoricoMisurazioni(progetto:Progetto_Firestore, isUpdate:boolean, layer:string){
    this.ref = this.dialogService.open(StoricoCaricamentiComponent, {
      data: {
        progetto: progetto,
        layer: layer
      },
      header: "Storico dei CARICAMENTI del progetto "+progetto.identificativo.toUpperCase() + " per il layer "+ layer.toUpperCase(),
      width:  "70%" ,
      height: "70%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confermaChiusura) => {
     
    });
  }
  

  eliminaProgetto(progetto:Progetto_Firestore){
    this.showDeleteConfirmation = true;
    this.selectedProgetto = progetto;
  }
 


  confermaEliminazioneProgetto(){
    this.showDeleteConfirmation = false;
    this.isLoadingResults = true;
    this.progettiService.elimina(this.selectedProgetto.id).subscribe( {
        next: (v) => {

        },
        error: (e) => {
            this.messageService.add({life: 10000,severity:'error', summary:'Errore', detail:'Errore in fase di eliminazione del progetto '+this.selectedProgetto.identificativo});
            this.isLoadingResults = false;
        },
        complete: () => {
            this.messageService.add({severity:'success', summary:'', detail:'Progetto '+ this.selectedProgetto.identificativo+ ' eliminato!', life:5000});
            this.loadProgetti();
            this.isLoadingResults = false;
            
        }
    });
  }


  showCaricamentoInCorso(statoCaricamento:String):boolean{
    return statoCaricamento==='IN CORSO';

  }
  

  loadAziende(){
    //this.logger.info("LOADING AZIENDE");
    this.aziendeService.get().subscribe( {
        next: (res) => {
            this.aziende = res;
            this.isLoadingResults = false;
        },
        error: (e) => {
         //   this.logger.info(e);
         // this.messageService.add({life: 10000,severity:'error', summary:'Errore', detail:'Errore in fase di caricamento aziende'});
          this.isLoadingResults = false;
      },
        complete: () => {

          this.isLoadingResults = false;
        }
    });
}

  getAziendaColor(idAzienda:number){
    const aziendaRes = this.aziende.filter((azienda)=>{return azienda.id == idAzienda})[0] as Azienda;
    if(aziendaRes!=null){
      return aziendaRes.colore;
    }
    return "";
  }

  loadProgetti(){
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_progetti).ref;
    let query  ;
    if(this.userService.isAdmin){
      //this.logger.info("LOAD PROGETTI @ HOME PER ADMIN");
      query = collectionRef.orderBy('creato', 'desc');

    }else{
      query = collectionRef.where("idAzienda", "==", this.userService.getUser().azienda.id).orderBy('creato', 'desc');

    }

    onSnapshot(query, (qs) => {
      this.progetti = [];
      this.isLoadingResults = false;

      qs.forEach((doc) => {
        let progetto = doc.data() as Progetto_Firestore;    
        this.progetti.push(progetto);
      });
    });
  }



  openCreaProgettoDialog(){
    this.ref = this.dialogService.open(CreaProgettoComponent, {
      header: "Nuovo Progetto",
      width:  "70%" ,
      height: "70%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirmNewProgetto) => {
       console.log("AFTER CLOSED > "+ confirmNewProgetto.success);
      if (confirmNewProgetto && confirmNewProgetto.success) {
        this.messageService.add({severity:'success', summary:'', detail:'Progetto creato con successo!', life:3000});
        this.loadProgetti();
      }
    });
  }
  
  verificaToken(){
    const headers = {
      // 'Authorization': `Bearer ${token}`,
     };
 
     const httpOptions = {
       headers,
       reportProgress: true,
     };
     this.http.get(environment.apiUrl+"security/verify-token", httpOptions).subscribe(
      {
        next: (res) => {
          console.info("OK TEST CALL");
          console.info(res);
        },
        error: (error) => {
          if (error.status === 401) {
            console.error("Unauthorized: Token non valido o scaduto.");
          } else {
            console.error("Errore nella chiamata HTTP:", error);
          }
        },
        complete: () => {
          console.info("Chiamata completata.");
        }
      }
     );
  }



}
