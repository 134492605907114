

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { Azienda } from "src/app/models/fragile/azienda.model";
import { Utente } from "src/app/models/fragile/utente.model";
import { RuoloUtente } from "src/app/models/fragile/utente.ruolo.model";
import { AziendeService } from "src/app/services/aziende.service";

import { UtentiService } from "src/app/services/utenti.service";
import { UtilService } from "src/app/services/util.service";


@Component({
  templateUrl: "./dialog-modifica-azienda.component.html",
  styleUrls: ["../aziende.component.css"],
})
export class ModificaAziendaComponent implements OnInit {
  protected _onDestroy = new Subject<void>();

  public modificaAziendaForm: FormGroup;
  public aziendaSelezionata: Azienda;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private aziendeService:AziendeService,


  ) {

  }

  ngOnInit(): void {
    this.aziendaSelezionata = this.config.data.azienda;
   
    this.modificaAziendaForm = this._formBuilder.group({
      ragioneSociale:           [ this.aziendaSelezionata.ragioneSociale, [Validators.required]],
      colore:           [ this.aziendaSelezionata.colore, [Validators.required]],

    });


  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let aziendaDaModificare: Azienda = new Azienda();
      aziendaDaModificare.id = this.aziendaSelezionata.id;
      aziendaDaModificare.ragioneSociale = this.modificaAziendaForm.value.ragioneSociale;
      aziendaDaModificare.colore = this.modificaAziendaForm.value.colore;
      
      this.aziendeService.modifica(aziendaDaModificare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          console.error("ERRORE MODIFICA AZIENDA" + error);
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di modifica AZIENDA!", life:10000
          });
        }
      );
    }
  }
}
