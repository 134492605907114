

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { UtentiService } from "src/app/services/utenti.service";
import { RuoloUtente } from "../../../models/fragile/utente.ruolo.model";
import { Azienda } from "../../../models/fragile/azienda.model";
import { AziendeService } from "src/app/services/aziende.service";
import { UtilService } from "src/app/services/util.service";
import { Utente } from "src/app/models/fragile/utente.model";


@Component({
  templateUrl: "./dialog-crea-utente.component.html",
  styleUrls: ["../utenti.component.css"],
})
export class CreaUtenteComponent implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected ruoli: RuoloUtente[];
  protected aziende: Azienda[]= [];
  public creaUtenteForm: FormGroup;
  public nome: String = "";
  public email: String = "";
  public password: String = "";
  public ruolo: RuoloUtente;
  public isRuoloUtente: Boolean = false;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private utentiService: UtentiService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private utilService:UtilService,
    private aziendeService:AziendeService,

  ) {

  }

  ngOnInit(): void {
    this.creaUtenteForm = this._formBuilder.group({
      nome:         [null, [Validators.required]],
      mail:         [null, [Validators.required, Validators.email]],
      password:     [null, [Validators.required]],
      azienda:      [null],
      ruolo:        [null, [Validators.required]],
    });
   // this.loadAziende();
    this.loadRuoliUtente();
  }
  loadRuoliUtente(){
    this.utilService.getRuoliUtente().subscribe(ruoliFromDB=>{
      this.ruoli = ruoliFromDB;
    });
  }

 

  loadAziende(){
    this.aziendeService.get().subscribe(aziendeFromDB=>{
      this.aziende = aziendeFromDB;
    });
  }
 

  getRuoloClass(ruolo: string): string {
    if (ruolo === "ADMIN") {
      return "ruolo ruolo-admin";
    }else if (ruolo === "UTENTE") {
      return "ruolo ruolo-utente";
    }
    return "";
  }

  onRuoloSelection(event) {
    let ruoloSelezionato:RuoloUtente = event.value;
    if(ruoloSelezionato.codice==="UTENTE"){
      this.isRuoloUtente = true;
      this.loadAziende();
    }else{
      this.isRuoloUtente = false;
      this.creaUtenteForm.value.azienda = null;
    }
   }

  


  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let utenteDaCreare: Utente = new Utente();
      utenteDaCreare.nome = this.creaUtenteForm.value.nome;
      utenteDaCreare.mail = this.creaUtenteForm.value.mail;
      utenteDaCreare.password = this.creaUtenteForm.value.password;
      utenteDaCreare.ruolo = this.creaUtenteForm.value.ruolo;
      if(this.creaUtenteForm.value.azienda){
        utenteDaCreare.azienda = this.creaUtenteForm.value.azienda;
      }else{
        const fragile:Azienda = new Azienda();
        fragile.id = 1;
        utenteDaCreare.azienda = fragile;
      }
     
      this.utentiService.crea(utenteDaCreare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          console.error("ERRORE CREAZIONE UTENTE" + error);
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di creazione utente!", life:10000
          });
        }
      );
    }
  }
}
