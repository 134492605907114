// STILI CSS

export const  STILI_STATO_CARICAMENTO = {
  "IN CORSO":   "stato-caricamento stato-caricamento-in_corso",
  "SOSPESO":    "stato-caricamento stato-caricamento-sospeso",
  "IN ERRORE":  "stato-caricamento stato-caricamento-in_errore",
  "COMPLETATO":  "stato-caricamento stato-caricamento-concluso",
  "ELIMINATO":  "stato-caricamento stato-caricamento-eliminato",
};

export const  STILI_STATO_PROGETTO = {
  "ATTIVO":   "stato-progetto stato-progetto-attivo",
  "SOSPESO":  "stato-progetto stato-progetto-sospeso",

};


