import { Azienda } from "./azienda.model";
import { RuoloUtente } from "./utente.ruolo.model";

export class Utente {
    id: number;
    nome: string;
    idAuth0:string;
    password:string;
    mail:string;
    ruolo:RuoloUtente;
    azienda:Azienda;
  }
