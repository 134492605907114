
export class Progetto {
    id:               Number;
    identificativo:   String;
    descrizione:      String;
    idAzienda:        String;
    separatoreCSV:    String;
    separatoreCSVASC:    String;
    separatoreCSVDESC:    String;
    separatoreCSVVERTICAL:    String;
    fileMisurazioni:  File;
    fileQGis:         File;
    fileGeoPackage:   File;
    fileMisurazioniASC:  File;
    fileMisurazioniDESC:  File;
    fileMisurazioniVERTICALE:  File;
  }
