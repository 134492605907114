import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { Timestamp } from "firebase/firestore";


@Injectable({
  providedIn: 'root',
})
export class UtilService{
  apiUrl: string = environment.apiUrl +"util";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}

  formattaData(data:Timestamp):string{
      if(data!=null){
        const date = data.toDate();
        const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`; // Ad esempio, formato DD/MM/YYYY
        return formattedDate;
      }
      return "";
  
    }

  formattaDataMisurazione(data:string):string{
    if(data!=null){
      let anno = data.substring(0, 4);  // primi 4 caratteri
      let mese = data.substring(4, 6);  // caratteri 5 e 6
      let giorno = data.substring(6, 8);
      let dataFormattata = `${giorno}/${mese}/${anno}`;

       return dataFormattata;
    }
    return "";

  }

  getRuoliUtente(): Observable<any> {
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl+"/ruoli-utente", httpOptions);
  }

  getStatiProgetto(): Observable<any> {
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl+"/stati-progetto", httpOptions);
  }

  getStatiCaricamento(): Observable<any> {
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl+"/stati-caricamento", httpOptions);
  }


}
