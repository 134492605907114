<div>
<form [formGroup]="modificaProgettoForm">
    <div class="grid formgrid p-fluid">
            
            <div class="field mt-2 mb-2 col-4">
                <label for="azienda" class="font-medium text-900 text-blue-600">AZIENDA</label>
                <span class="p-input-icon-left">
                    <i class="pi pi-bookmark"></i>
                    <p-dropdown inputId="azienda" [options]="aziende" optionLabel="ragioneSociale" [showClear]="true"
                        placeholder="Scegli l'azienda" formControlName="azienda">
                        <ng-template let-azienda pTemplate="item">
                            <div class="flex align-items-center">
                                <div [style]="{
                                    'background-color': azienda.colore ,
                                    'color': '#ffffff' ,
                                    'border-radius': '6px',
                                    'padding': '.25em .5rem',
                                    'font-weight': '700',
                                    'font-size': '13px',
                                    'letter-spacing': '.3px;',
                                    'cursor': 'pointer',
                                  }">{{azienda.ragioneSociale | uppercase}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </span>
            </div>
            <div class="field mt-2 mb-2 col-8">
                <label for="descrizione" class="font-medium text-900 text-orange-400">DESCRIZIONE</label>
                <span class="p-input-icon-left">
                    <i class="pi pi-info-circle"></i>
                    <textarea id="descrizione" rows="3" [autoResize]="true" formControlName="descrizione" pInputTextarea></textarea>
                </span>
            </div>

    </div>
</form>
<div class="surface-border border-top-1 opacity-50 mt-3 col-12"></div>

<div class="mb-2" style="text-align: center; display: flex; justify-content: center; align-items: center;">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
        styleClass="p-button-success"
        [disabled]="!modificaProgettoForm.valid"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
        styleClass="p-button-warning"></p-button>
</div>
</div>