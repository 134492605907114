import { CommonModule, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { AppComponent } from "./app.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "../environments/environment";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ButtonModule } from "primeng/button";
import { LottieModule } from 'ngx-lottie';
import { ChartModule } from 'primeng/chart';
import { RadioButtonModule } from 'primeng/radiobutton';

import player from 'lottie-web';

//PRIME NG MODULES
//PRIME NG MODULES
import { StyleClassModule } from "primeng/styleclass";
import { ToastModule } from "primeng/toast";
import { TableModule } from "primeng/table";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { TooltipModule } from "primeng/tooltip";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { PasswordModule } from 'primeng/password';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { StepsModule } from 'primeng/steps';
import { KnobModule } from 'primeng/knob';
import { TagModule } from 'primeng/tag';
import { BadgeModule } from 'primeng/badge';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ListboxModule } from 'primeng/listbox';
import { SliderModule } from 'primeng/slider';
import {FileUploadModule} from 'primeng/fileupload';
import { TreeTableModule } from 'primeng/treetable';
import { DragDropModule } from 'primeng/dragdrop';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { MenuModule } from "primeng/menu";
import { ChipModule } from 'primeng/chip';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TimelineModule } from 'primeng/timeline';
import {Ripple} from "primeng/ripple";
import { PickListModule } from 'primeng/picklist';
import { ColorPickerModule } from "primeng/colorpicker";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { HomeComponent } from "./pages/home/home.component";
import {AngularFireModule} from "@angular/fire/compat";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { MisurazioniComponent } from "./pages/misurazioni/misurazioni.component";

import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

import { NotLoggedHomeComponent } from "./components/home/not_logged.component";
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { NavBarComponent } from "./components/navbar/navbar.component";
import { UtentiComponent } from "./pages/utenti/utenti.component";
import { CreaUtenteComponent } from "./pages/utenti/crea/crea-utente";
import { ModificaUtenteComponent } from "./pages/utenti/modifica/modifica-utente";
import { AziendeComponent } from "./pages/aziende/aziende.component";
import { CreaAziendaComponent } from "./pages/aziende/crea/crea-azienda";
import { ModificaAziendaComponent } from "./pages/aziende/modifica/modifica-azienda";
import { CreaProgettoComponent } from "./pages/home/crea-progetto/crea-progetto";
import { CreaProgettoLoadingComponent } from "./pages/home/crea-progetto/crea-progetto-loading.component";
import { StoricoCaricamentiComponent } from "./pages/home/storico-caricamenti/storico-caricamenti.component";
import { CaricaStoricoLoadingComponent } from "./pages/home/storico-caricamenti/carica-storico-loading.component";
import { CaricaFileQGIComponent } from "./pages/home/carica-file/carica-qgis";
import { CaricaFileGEOPackageComponent } from "./pages/home/carica-file/carica-geoPackage";
import { CaricaFileCSVComponent } from "./pages/home/carica-file/carica-csv";
import { ModificaProgettoComponent } from "./pages/home/modifica-progetto/modifica-progetto";



export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    MisurazioniComponent,
    UtentiComponent,
    CreaUtenteComponent,
    ModificaUtenteComponent,
    AziendeComponent,
    CreaAziendaComponent,
    ModificaAziendaComponent,
    CreaProgettoComponent,
    CreaProgettoLoadingComponent,
    StoricoCaricamentiComponent,
    CaricaStoricoLoadingComponent,
    CaricaFileQGIComponent,
    CaricaFileGEOPackageComponent,
    CaricaFileCSVComponent,
    ModificaProgettoComponent,
    NotLoggedHomeComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    StyleClassModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ButtonModule,
    CascadeSelectModule,
    TableModule,
    ConfirmDialogModule,
    ToastModule,
    TooltipModule,
    InputSwitchModule,
    InputTextModule,
    DropdownModule,
    PasswordModule,
    CalendarModule,
    InputNumberModule,
    DialogModule,
    InputTextareaModule,
    FileUploadModule,
    ProgressSpinnerModule,
    ToggleButtonModule,
    StepsModule,
    SliderModule,
    KnobModule,
    BadgeModule,
    ChipModule,
    MenuModule,
    TabViewModule,
    TieredMenuModule,
    TagModule,
    DragDropModule,
    PickListModule,
    SelectButtonModule,
    MultiSelectModule,
    AutoCompleteModule,
    TimelineModule,
    ColorPickerModule,
    HttpClientModule,
    ChartModule,
    RadioButtonModule,
    AngularFireModule.initializeApp(environment.firebase),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: false,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
     
    }),
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0.audience,
      },
      httpInterceptor: {
        
        allowedList: [
         // `${environment.apiUrl}` + "security/*",
         `${environment.apiUrl}` + "util",
         `${environment.apiUrl}` + "util/*",
         `${environment.apiUrl}` + "utente",
         `${environment.apiUrl}` + "utente/*",
         `${environment.apiUrl}` + "azienda",
         `${environment.apiUrl}` + "azienda/*",
         `${environment.apiUrl}` + "progetto",
         `${environment.apiUrl}` + "progetto/*",
        ],
      }
    }),
    LottieModule.forRoot({player: playerFactory}),

  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    MessageService,
    DialogService,
    //VerticalAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
   
  ],
  bootstrap: [AppComponent],
  exports: [
    
  ]
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http);
}
