
<ng-template [ngIf]="isLoadingResults">
  <app-crea-progetto-loading></app-crea-progetto-loading>
</ng-template>

<div >
<p-timeline [value]="caricamenti">
  <ng-template pTemplate="marker" let-caricamento>
    <span
        class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1 material-symbols-outlined small-icon"
        [ngClass]="stiliService.getMaterialIconStatoCaricamento(caricamento)"
        [style]="{ 'background-color': stiliService.getColoreIconaStatoCaricamento(caricamento) }">
        {{ stiliService.getMaterialIconStatoCaricamento(caricamento) }}
           <!--   -->
    </span>
</ng-template>
  <ng-template pTemplate="content" let-caricamento>
    <span *ngIf="caricamento.stato.label!=='CONCLUSO'" style="font-family: 'Poppins';" [ngStyle]="{'color':stiliService.getColoreIconaStatoCaricamento(caricamento)}" class="mt-1"><strong>{{ formatDate(caricamento.iniziato) }}</strong></span>
    
    <span *ngIf="caricamento.stato.label==='CONCLUSO'"style="font-family: 'Poppins';" class="mt-1">Il <strong>{{ formatDate(caricamento.iniziato) }}</strong> sono state caricate <strong>{{caricamento.numeroMisurazioni}}</strong> misurazioni.</span>
  
  </ng-template>
  <ng-template pTemplate="opposite" let-caricamento>
    <span style="font-family: 'Poppins'; font-weight: bold;" class="mt-3" [style]="{ 'color': stiliService.getColoreIconaStatoCaricamento(caricamento) }">{{ formattaCaricamento(caricamento) }}</span>

  </ng-template>
</p-timeline>
<div class="border-top-1 surface-border mt-5 flex-container">
  <button pButton pRipple icon="pi pi-times" (click)="dialogRef.close(true)" label="CHIUDI" class="p-button-outlined w-6"></button>
</div>

<div>
