<form [formGroup]="modificaUtenteForm">
    <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12">
            <label for="nome" class="font-medium text-900">Nome</label>
            <span class="p-input-icon-left">
                <i class="pi pi-user"></i>
                <input id="nome" type="text" type="nome" formControlName="nome" pInputText>
            </span>
        </div>
        <div class="field mb-4 col-6 md:col-6">
            <label for="ruolo" class="font-medium text-900 text-blue-600">Ruolo</label>
            <p-dropdown inputId="ruolo" [options]="ruoli" optionLabel="codice" [showClear]="true"
            placeholder="Scegli il ruolo" formControlName="ruolo" (onChange)="onRuoloSelection($event)">
            <ng-template let-ruolo pTemplate="item">
                <div class="flex align-items-center">
                    <div [ngClass]="getRuoloClass(ruolo.codice)">{{ruolo.codice}}</div>
                </div>
            </ng-template>
        </p-dropdown>
        </div>
        <div *ngIf="isRuoloUtente" class="field mb-4 col-6 md:col-6">
            <label for="azienda" class="font-medium text-900 text-blue-600">Azienda</label>
            <p-dropdown inputId="azienda" [options]="aziende" optionLabel="ragioneSociale" [showClear]="true"
                placeholder="Scegli l'azienda" formControlName="azienda">
                <ng-template let-azienda pTemplate="item">
                    <div class="flex align-items-center">
                        <p-tag
                            [value]="azienda.ragioneSociale | uppercase"
                            [style]="{
                            'background-color': azienda.colore ,
                            'color': '#ffffff' ,
                            'border-radius': '6px',
                            'padding': '.25em .5rem',
                            'font-weight': '700',
                            'font-size': '13px',
                            'letter-spacing': '.3px;',
                            'cursor': 'pointer',
                            }"
                            class="mr-1">
                        </p-tag>
                        <!-- <div >{{azienda.ragioneSociale}}</div> -->
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>
</form>
<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
        styleClass="p-button-success"
        [disabled]="!modificaUtenteForm.valid"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
        styleClass="p-button-warning"></p-button>
</div>
