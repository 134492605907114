import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';


@Injectable({
  providedIn: 'root',
})
export class SecurityService{
  apiUrl: string = environment.apiUrl +"security";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}


  validateToken(token: String): Observable<any> {
    const headers = {
      'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };

    this.logger.info(httpOptions);
    return this.http.post(this.apiUrl+"/verify-token",null, httpOptions);
  }

  




}
