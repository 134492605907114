

import {
    Component,
    OnInit,
  } from "@angular/core";
  import { NGXLogger } from "ngx-logger";
  import { ConfirmationService, MessageService } from "primeng/api";


  import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
  } from "primeng/dynamicdialog";


  import { Subject } from "rxjs";
import { Progetto_Firestore } from "src/app/models/firestore/progettoFirestore.model";
import { StatoCaricamentoEnum } from "src/app/models/fragile/stato.caricamento.model";
import { StoricoCaricamento } from "src/app/models/fragile/storico.caricamento.model";
import { ProgettiService } from "src/app/services/progetti.service";
import { StiliService } from "src/app/services/stili.service";


@Component({
    selector: "app-storico-caricamenti",
    templateUrl: './storico-caricamenti.component.html',
    styleUrls: ['../home.component.css']
  })
  export class StoricoCaricamentiComponent implements OnInit {


    protected _onDestroy = new Subject<void>();
    protected progetto:Progetto_Firestore;
    protected layer:string;

    protected caricamenti:StoricoCaricamento[]=[];
    protected ref: DynamicDialogRef;
    protected isLoadingResults = false;


    constructor(
        private logger: NGXLogger,
        public dialogRef: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public dialogService: DialogService,
        private progettiService:ProgettiService,
        public stiliService:StiliService,
      ) {
      this.progetto = this.config.data.progetto;
      this.layer = this.config.data.layer;
      
      this.loadStoricoCaricamenti();
    }

    ngOnInit(): void {

    }
    loadStoricoCaricamenti(){
      this.isLoadingResults = true;
      this.progettiService.getStoricoCaricamenti(this.progetto.id, this.layer).subscribe(caricamentiFromDB=>{
        this.caricamenti = caricamentiFromDB;
        this.logger.info(this.caricamenti);
        this.isLoadingResults = false;
      });
    }
    formatDate(dateString) {
        const date = new Date(dateString.replace('[UTC]', ''));

        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // I mesi partono da 0
        const year = date.getUTCFullYear();

        return `${day}/${month}/${year}`;
    }

    formattaCaricamento(storico:StoricoCaricamento):string{

      switch (storico.stato.label) {
        case StatoCaricamentoEnum.IN_CORSO:
          return "IN CORSO";
        case StatoCaricamentoEnum.SOSPESO:
          return "SOSPESO";
        case StatoCaricamentoEnum.IN_ERRORE:
          return "IN ERRORE";
        case StatoCaricamentoEnum.CONCLUSO:
          return "CONCLUSO";
          case StatoCaricamentoEnum.ELIMINATO:
            return "ELIMINATO";
        default:
          return "IN CORSO";
      }
    }

  

   



    conferma(conferma: boolean) {
      if (!conferma) {
        this.dialogRef.close({ success: false });
      } else {
        this.dialogRef.close({
          success: true
        });
      }
    }
  }
