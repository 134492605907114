import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  constructor(
    private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const allowedRoles = route.data.allowedRoles;
    console.log("RoleGuard " + allowedRoles);
    console.log("RoleGuard " + localStorage.getItem("isLoggedIn"));
    console.log("RoleGuard " +localStorage.getItem("ruolo"));
    if (localStorage.getItem("isLoggedIn") && allowedRoles.includes(localStorage.getItem("ruolo"))) {

      return true;
    }
    // Reindirizza l'utente alla pagina di accesso o alla pagina non autorizzata
    return this.router.parseUrl('');
  }
}
