import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { Azienda } from '../models/fragile/azienda.model';


@Injectable({
  providedIn: 'root',
})
export class AziendeService{
  apiUrl: string = environment.apiUrl +"azienda";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}


  get(): Observable<any> {
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions);
  }

  getById(idAzienda:string): Observable<any> {
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl+"/"+idAzienda, httpOptions);
  }
  

  crea(azienda:Azienda): Observable<any> {
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, azienda, httpOptions);

  }


  modifica(azienda:Azienda): Observable<any> {

    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(this.apiUrl+"/"+azienda.id, azienda, httpOptions);
  }

  elimina(idAzienda:Number): Observable<any> {
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(this.apiUrl+"/"+idAzienda, httpOptions);

  }




}
