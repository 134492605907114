import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';

import { NGXLogger } from 'ngx-logger';
import { AngularFirestore } from "@angular/fire/compat/firestore";

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';


import { Utente } from "src/app/models/fragile/utente.model";
import { UtentiService } from 'src/app/services/utenti.service';
import { Azienda } from 'src/app/models/fragile/azienda.model';
import { CreaUtenteComponent } from './crea/crea-utente';
import { ModificaUtenteComponent } from './modifica/modifica-utente';
import { ActivatedRoute, Router } from '@angular/router';
import { AziendeService } from 'src/app/services/aziende.service';
import { Location } from "@angular/common";

declare const CSVBoxImporter: any;

@Component({
    templateUrl: './utenti.component.html',
    styleUrls: ['./utenti.component.css'],
    providers: [DialogService, ConfirmationService, MessageService],

})
export class UtentiComponent implements OnInit, OnDestroy {
    protected utenti:Utente[]=[];
    protected utentiOriginali:Utente[]=[];
    protected selectedUtente:Utente;

    protected isLoadingResults:boolean = false;
    protected showDeleteConfirmation:boolean = false;
    protected nascondiUtentiFragile:boolean = true;
    protected aziendaFiltro:Azienda;

    private ref: DynamicDialogRef;



    @ViewChild("utentiDT", { static: true }) utentiDT: any;

    public filtroAzienda:string;
    protected filterIsSet:Boolean = false;

    constructor(
        private logger: NGXLogger,
        private firestore: AngularFirestore,
        private dialogService: DialogService,
        private messageService: MessageService,
        private utentiService:UtentiService,
        private aziendeService:AziendeService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private location: Location,
    ) {}

    ngOnDestroy(): void {
        //throw new Error('Method not implemented.');
    }

    ngOnInit() {
        this.logger.info("INIT UTENTI ");
        this.logger.info(this.activatedRoute.snapshot.paramMap);
        const urlParamAzienda = this.activatedRoute.snapshot.paramMap.get('idAzienda');
        if(urlParamAzienda){
          this.aziendeService.getById(urlParamAzienda).subscribe(aziendaFromDB=>{
            this.aziendaFiltro = aziendaFromDB;
          });
          this.loadUtentiAzienda(urlParamAzienda);
          this.filtroAzienda = urlParamAzienda;
          this.filterIsSet = true;
        }else {
          this.loadUtenti();
        }
        
    }
    loadUtentiAzienda(idAzienda:string){
      this.logger.info("LOADING UTENTI DELL'AZIENDA "+ idAzienda);
      this.utentiService.getByAzienda(idAzienda).subscribe( {
          next: (res) => {
              this.utenti = res;
              //this.utentiOriginali = res;
          },
          error: (e) => {
              this.logger.info(e);
            this.messageService.add({life: 10000,severity:'error', summary:'Errore', detail:'Errore in fase di caricamento utenti'});
            this.isLoadingResults = false;
        },
          complete: () => {

            this.isLoadingResults = false;
          }
      });
  }

    reloadDataRemovingFilter(){
      this.filterIsSet = false;
      this.filtroAzienda = null;
      this.location.replaceState('/segnalazioni');
      this.loadUtenti();
    }
    loadUtenti(){
        this.logger.info("LOADING DATA");
        this.utentiService.get().subscribe( {
            next: (res) => {
                this.utenti = res;
                this.logger.info(this.utenti);

                //this.utentiOriginali = res;
            },
            error: (e) => {
                this.logger.info(e);
              this.messageService.add({life: 10000,severity:'error', summary:'Errore', detail:'Errore in fase di caricamento utenti'});
              this.isLoadingResults = false;
          },
            complete: () => {

              this.isLoadingResults = false;
            }
        });
    }
    filtraUtentiVertical(){
        this.logger.info("* filtraUtentiVertical "+ this.nascondiUtentiFragile);
        if (!this.nascondiUtentiFragile) {
            //this.utentiDT.filter(1, "azienda.id", "is");

            //this.utentiDT.filterGlobal("Vertical srl", 'not contains');
            this.utenti = this.utenti.filter(utente => utente.azienda && utente.azienda.ragioneSociale !== 'Vertical srl');
        } else {
            //this.utentiDT.clear();
            this.utenti = this.utentiOriginali;
        }
    }

    getRuoloClass(ruolo: string): string {
        if (ruolo === "SUPERADMIN") {
          return "ruolo ruolo-superadmin";
        }else if (ruolo === "ADMIN") {
          return "ruolo ruolo-admin";
        }else if (ruolo === "UTENTE") {
          return "ruolo ruolo-utente";
        }
        return "";
      }

      getAziendaClass(azienda: Azienda): string {
        if (azienda.ragioneSociale.toUpperCase() === "VERTICAL SRL") {
          return "azienda-vertical";
        }else{
          return "azienda-altre";
        }
      }



      filtraUtenti(event: any){
        this.utentiDT.filterGlobal(event.target.value, 'contains');
      }

       openCreaUtenteDialog(){
        this.ref = this.dialogService.open(CreaUtenteComponent, {
            header: "Nuovo Utente",
            width:  "50%" ,
            height: "70%",
            baseZIndex: 10000,
            closable: true,
            draggable: false,
          });
          this.ref.onClose.subscribe((confirmNewUtente) => {
             console.log("AFTER CLOSED > "+ confirmNewUtente.success);
            if (confirmNewUtente && confirmNewUtente.success) {
              this.messageService.add({severity:'success', summary:'', detail:'Utente creato con successo!', life:3000});
              this.loadUtenti();
            }
          });

      } 


    openDeleteUtente(utente: Utente): void {
        this.showDeleteConfirmation = true;
        this.selectedUtente = utente;
        this.logger.info(this.selectedUtente)
     }



    confermaEliminazioneUtente(){
      this.showDeleteConfirmation = false;
      this.isLoadingResults = true;
      this.utentiService.elimina(this.selectedUtente.id).subscribe( {
          next: (v) => {

          },
          error: (e) => {
              this.messageService.add({life: 10000,severity:'error', summary:'Errore', detail:'Errore in fase di eliminazione utente'});
              this.isLoadingResults = false;
          },
          complete: () => {
              this.messageService.add({severity:'success', summary:'', detail:'Utente eliminato!', life:5000});
            this.loadUtenti();
              this.isLoadingResults = false;
              
          }
      });

    }


     openModificaUtenteDialog(utente: Utente): void {
        this.ref = this.dialogService.open(ModificaUtenteComponent, {
            data: {
                utente: utente,
              },
            header: "Modifica Utente",
            width:  "50%" ,
            height: "60%",
            baseZIndex: 10000,
            closable: true,
            draggable: false,
          });
          this.ref.onClose.subscribe((confirmModificaUtente) => {
             console.log("AFTER CLOSED > "+ confirmModificaUtente.success);
            if (confirmModificaUtente && confirmModificaUtente.success) {
              this.messageService.add({severity:'success', summary:'', detail:'Utente modificato con successo!', life:3000});
              this.loadUtenti();
            }
          });
    } 








}
